/*-----------------------------------------------------------------------------------
    
    Template Name: Young - eCommerce HTML Template
    Version: 1.0
    
-----------------------------------------------------------------------------------
    
    CSS INDEX
    ===================
    1. Template default CSS
    2. Header style
    3. Slider style
    4. Section title style
    5. Product style
    6. Banner style
    7. Video style
    8. Blog style
    9. Subscribe style
    10. Footer style
    11. Instagram style
    12. Service style
    13. Categories style
    14. Brand logo style
    15. Testimonial style
    16. Bookstore style
    17. Others style
    18. About style
    19. Product details style
    20. Breadcrumb style
    22. Shop style
    23. Sidebar style
    24. Cart style
    25. Order tracking style
    26. Login register style
    27. My account style
    28. Checkout style
    29. Coming soon style
    30. Contact style
    31. Faq style
    32. Compare style
    
-----------------------------------------------------------------------------------*/
@import 'variabls';
@import 'common';
@import 'header';
@import 'slider';
@import 'section-title';
@import 'product';
@import 'banner';
@import 'video';
@import 'blog';
@import 'subscribe';
@import 'footer';
@import 'instagram';
@import 'service';
@import 'categories';
@import 'brand-logo';
@import 'testimonial';
@import 'bookstore';
@import 'others';
@import 'about';
@import 'product-details';
@import 'breadcrumb';
@import 'shop';
@import 'sidebar';
@import 'cart';
@import 'order-tracking';
@import 'login-register';
@import 'my-account';
@import 'checkout';
@import 'coming-soon';
@import 'contact';
@import 'faq';
@import 'compare';




