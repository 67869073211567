/*-------- 15. Testimonial style ---------*/

.single-testimonial {
    .testimonial-quote {
        i {
            color: $theme-color-yellow;
            background-color: #fff;
            display: inline-block;
            height: 50px;
            width: 50px;
            border-radius: 100%;
            text-align: center;
            line-height: 50px;
            font-size: 20px;
        }
    }
    p {
        font-size: 22px;
        line-height: 41px;
        font-weight: 600;
        margin: 12px auto 38px;
        color: #fff;
        width: 67%;
        @media #{$lg-layout} {
            width: 85%;
        }
        @media #{$md-layout} {
            width: 90%;
            font-size: 20px;
        }
        @media #{$xs-layout} {
            width: 90%;
            font-size: 16px;
            line-height: 32px;
            margin: 12px auto 25px;
        }
    }
}

.client-info {
    span {
        color: #fff;
        font-size: 18px;
        display: block;
        margin: 6px 0 0;
    }
}

.single-testimonial-2 {
    position: relative;
    overflow: hidden;
    padding-top: 40px;
    padding-bottom: 12px;
    .testimonial-2-inner {
        position: relative;
        float: left;
        width: 100%;
        &:before {
            position: absolute;
            left: 0;
            top: 0;
            background-color: #fff;
            content: "";
            width: 2px;
            height: 100%;
        }
        &:after {
            position: absolute;
            right: 0;
            top: 0;
            background-color: #fff;
            content: "";
            width: 2px;
            height: 100%;
        }
        .testimonial-quote-2 {
            position: relative;
            margin-top: -40px;
            overflow: hidden;
            display: flex;
            justify-content: center;
            @media #{$xs-layout} {
                margin-top: -24px;
            }
            .quote-2-icon {
                position: relative;
                &:before {
                    position: absolute;
                    left: auto;
                    right: calc(100% + 100px);
                    top: 50%;
                    background-color: #fff;
                    content: "";
                    width: 700%;
                    height: 2px;
                    transform: translateY(-50%);
                    @media #{$xs-layout} {
                        right: calc(100% + 50px);
                    }
                }
                &:after {
                    position: absolute;
                    right: auto;
                    left: calc(100% + 100px);
                    top: 50%;
                    background-color: #fff;
                    content: "";
                    width: 700%;
                    height: 2px;
                    transform: translateY(-50%);
                    @media #{$xs-layout} {
                        left: calc(100% + 50px);
                    }
                }
                i {
                    color: #fff;
                    font-size: 82px;
                    @media #{$md-layout} {
                        font-size: 75px;
                    }
                    @media #{$xs-layout} {
                        font-size: 50px;
                    }
                }
            }
        }
        p {
            color: #fff;
            font-size: 66px;
            font-weight: bold;
            font-family: $rufina;
            margin: 0 0 63px;
            line-height: 107px;
            @media #{$lg-layout} {
                font-size: 50px;
                margin: 0 0 63px;
                line-height: 80px;
            }
            @media #{$md-layout} {
                font-size: 28px;
                margin: 28px 0 40px;
                line-height: 55px;
            }
            @media #{$xs-layout} {
                font-size: 22px;
                line-height: 37px;
                margin: 15px 0 25px;
                padding: 0 20px;
            }
        }
        .client-info-2 {
            margin-bottom: -12px;
            overflow: hidden;
            display: flex;
            justify-content: center;
            span {
                color: #fff;
                font-size: 24px;
                font-weight: bold;
                font-family: $rufina;
                display: block;
                position: relative;
                @media #{$xs-layout} {
                    font-size: 16px;
                }
                &:before {
                    position: absolute;
                    left: auto;
                    right: calc(100% + 100px);
                    top: 50%;
                    background-color: #fff;
                    content: "";
                    width: 500%;
                    height: 2px;
                    transform: translateY(-50%);
                    @media #{$xs-layout} {
                        right: calc(100% + 50px);
                    }
                }
                &:after {
                    position: absolute;
                    right: auto;
                    left: calc(100% + 100px);
                    top: 50%;
                    background-color: #fff;
                    content: "";
                    width: 500%;
                    height: 2px;
                    transform: translateY(-50%);
                    @media #{$xs-layout} {
                        left: calc(100% + 50px);
                    }
                }
            }
        }
    }
}

.single-testimonial-3 {
    p {
        font-size: 28px;
        line-height: 39px;
        margin: 15px auto 45px;
        width: 63%;
        font-family: $playfairdisplay;
        @media #{$xx-layout} {
            font-size: 25px;
        }
        @media #{$xl-layout} {
            font-size: 23px;
            width: 83%;
        }
        @media #{$lg-layout} {
            font-size: 20px;
            width: 85%;
        }
        @media #{$md-layout} {
            font-size: 20px;
            width: 90%;
        }
        @media #{$xs-layout} {
            font-size: 16px;
            line-height: 30px;
            margin: 15px auto 25px;
            width: 100%;
        }
    }
}

.testimonial3-negative-mt {
    margin-top: -240px;
    @media #{$xs-layout} {
        margin-top: -80px;
    }
}
.testimonial3-pt {
    padding-top: 350px;
    @media #{$md-layout} {
        padding-top: 305px;
    }
    @media #{$xs-layout} {
        padding-top: 125px;
    }
}

.testimonial-area {
    position: relative;
    overflow: hidden;
} 
.handmade-leaf-1 {
	position: absolute;
	right: 0;
	bottom: 0;
    animation-duration: 2.5s;
    animation-name: bounce-img-2;
    animation-iteration-count: infinite;
    img {
        width: 597px; 
        @media #{$xx-layout} {
            width: 350px;
        }
        @media #{$xl-layout} {
            width: 250px;
        }
        @media #{$lg-layout} {
            width: 200px;
        }
        @media #{$md-layout} {
            width: 150px;
        }
        @media #{$xs-layout} {
            width: 100px;
        }
    }
}
.handmade-leaf-2 {
	position: absolute;
	left: 0;
	bottom: 0;
    animation-duration: 2.5s;
    animation-name: bounce-img-2;
    animation-iteration-count: infinite;
    img {
        width: 486px;
        @media #{$xx-layout} {
            width: 380px;
        }
        @media #{$xl-layout} {
            width: 280px;
        }
        @media #{$lg-layout} {
            width: 230px;
        }
        @media #{$md-layout} {
            width: 180px;
        }
        @media #{$xs-layout} {
            width: 100px;
        }
    }
}
.testimonial-border {
	border: 1px solid #979797;
    padding: 0 0 55px;
}
.single-testimonial-4 {
    p {
        font-size: 18px;
        line-height: 36px;
        margin: 9px auto 19px;
        width: 64%;
        @media #{$lg-layout} {
            width: 78%;
        }
        @media #{$md-layout} {
            width: 73%;
        }
        @media #{$xs-layout} {
            font-size: 14px;
            line-height: 30px;
        }
        @media #{$sm-layout} {
            font-size: 16px;
            line-height: 34px;
        }
    }
}
.nav-style-4 {
    .owl-nav {
        div {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 40px;
            font-size: 30px;
            transition: all .3s ease 0s;
            @media #{$xs-layout} {
                left: 15px;
                font-size: 20px;
            }
            &:hover {
                color: $theme-color-yellow-2;
            }
            &.owl-next {
                left: auto;
                right: 40px;
                @media #{$xs-layout} {
                    right: 15px;
                }
            }
        }
    }
}

.single-testimonial-5 {
    p {
        font-size: 36px;
        font-family: $fredokaone;
        line-height: 49px;
        margin: 27px auto 36px;
        width: 68%;
        @media #{$xl-layout} {
            font-size: 30px;
        }
        @media #{$lg-layout} {
            font-size: 30px;
            line-height: 45px;
        }
        @media #{$md-layout} {
            font-size: 25px;
            line-height: 40px;
            width: 100%;
        }
        @media #{$xs-layout} {
            font-size: 20px;
            line-height: 36px;
            margin: 16px auto 26px;
            width: 100%;
        }
    }
}

.testimonial-5-ptb {
	padding: 148px 0 142px;
    @media #{$xs-layout} {
        padding: 70px 0 70px;
    }
}

@media #{$md-layout} {
    .testimonial-area {
        &.pt-150 {
            padding-top: 100px;
        }
        &.pb-150 {
            padding-bottom: 100px;
        }
    }
    .testimonial-area-2 {
        &.pt-200 {
            padding-top: 120px;
        }
    }
}

@media #{$xs-layout} {
    .testimonial-area {
        &.pt-150 {
            padding-top: 70px;
        }
        &.pb-150 {
            padding-bottom: 70px;
        }
    }
    .testimonial-area-2 {
        &.pt-200 {
            padding-top: 115px;
        }
    }
}









