/*-------- 19. Product details style ---------*/

.modal-backdrop.show {
	opacity: .7;
}
.modal-dialog {
	margin: 6% auto 8%;
	max-width: 1250px;
	width: 1250px;
    @media #{$xl-layout} {
        width: 1150px;
        max-width: 1150px;
    }
    @media #{$lg-layout} {
        width: 960px;
        max-width: 960px;
    }
    @media #{$md-layout} {
        width: 720px;
        max-width: 720px;
    }
    @media #{$xs-layout} {
        width: 100%;
        max-width: 100%;
        padding: 35px 0;
    }
    .modal-content {
        border-radius: 0;
        .modal-header {
            border: none;
            padding: 0;
            right: -13px;
            top: -54px;
            position: absolute;
            z-index: 9999;
            @media #{$xs-layout} {
                right: 0px;
                top: -34px;
            }
            .close {
                color: #fff;
                cursor: pointer;
                opacity: 1;
                padding: 0;
                margin: 0;
                width: 54px;
                height: 54px;
                line-height: 57px;
                background-color: #292929;
                border-radius: 100%;
                font-size: 18px;
                text-shadow: none;
                font-weight: 300;
                transition: all .3s ease 0s;
                @media #{$xs-layout} {
                    width: 34px;
                    height: 34px;
                    line-height: 34px;
                    font-size: 15px;
                }
                &:hover {
                    color: $theme-color-yellow;
                    transform: rotate(90deg);
                }
            }
        }
        .modal-body {
            padding: 0px;
        }
    }
}

.quickview-content-padding {
    padding: 50px 50px 50px;
    @media #{$lg-layout} {
        padding: 15px 35px 20px;
    }
    @media #{$md-layout} {
        padding: 20px 35px 35px;
    }
    @media #{$xs-layout} {
        padding: 15px 15px 15px;
    }
}

.product-details-content {
    .pro-dec-next-prev {
        text-align: right;
        a {
            font-size: 20px;
            margin-left: 12px;
            &:first-child {
                margin-left: 0;
            }
        }
    }
    > span {
        color: #C3832D;
        margin: 0 0 7px;
        text-transform: uppercase;
    }
    h2 {
        font-size: 24px;
        margin: 0 0 20px;
        color: #262626;
        @media #{$lg-layout} {
            font-size: 20px;
        }
        @media #{$xs-layout} {
            font-size: 20px;
        }
    }
    .pro-dec-info-wrap {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        padding-bottom: 10px;
        margin-bottom: 32px;
        @media #{$xx-layout} {
            display: block;
            &.pro-dec-info-wrap2 {
                display: flex;
            }
        }
        @media #{$xl-layout} {
            display: block;
            &.pro-dec-info-wrap2 {
                display: flex;
            }
        }
        @media #{$lg-layout} {
            &.pro-dec-info-wrap2 {
                display: block;
            }
            &.pro-dec-info-wrap3 {
                display: block;
            }
        }
        @media #{$md-layout} {
            display: block;
            &.pro-dec-info-wrap2 {
                display: flex;
            }
        }
        @media #{$xs-layout} {
            display: block;
        }
        @media #{$sm-layout} {
            display: flex;
        }
        .pro-dec-ratting-wrap {
            display: flex;
            .pro-dec-ratting {
                i {
                    color: #d5d6d8;
                    font-size: 14px;
                    margin-left: 2px;
                    &.yellow {
                        color: #f5b223;
                    }
                }
            }
            a {
                color: #1D1D1D;
                font-size: 12px;
                margin-left: 10px;
            }
        }
        .pro-dec-stock {
            ul {
                display: flex;
                flex-wrap: wrap;
                li {
                    margin-left: 10px;
                    color: #1D1D1D;
                    font-size: 12px;
                    &.green {
                        color: #86BA45;
                        font-weight: 600;
                    }
                    span {
                        font-weight: 600;
                    }
                    i {
                        margin-right: 3px;
                    }
                    &:first-child {
                        margin-left: 0px;
                    }
                }
            }
        }
    }
    > h3 {
        font-size: 32px;
        margin: 0px 0 10px;
        font-weight: bold;
        color: #262626;
    }
    .product-details-peragraph {
        p {
            font-size: 14px;
            line-height: 27px;
            color: #737373;
        }
    }
    .product-details-action-wrap {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin: 45px 0 0px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        padding: 0 0 23px;
        @media #{$xs-layout} {
            margin: 20px 0 0px;
        }
        .product-details-cart {
            margin: 0 15px 0 15px;
            @media #{$xs-layout} {
                margin: 0 7px 7px 7px;
            }
            @media #{$sm-layout} {
                margin: 0 15px 0 15px;
            }
            a {
                display: inline-block;
                font-size: 16px;
                color: #fff;
                padding: 22px 80px;
                background-color: #262626;
                font-weight: bold;
                line-height: 1;
                @media #{$xx-layout} {
                    padding: 22px 50px;
                }
                @media #{$xl-layout} {
                    padding: 22px 25px;
                }
                @media #{$lg-layout} {
                    padding: 22px 30px;
                }
                @media #{$xs-layout} {
                    padding: 22px 18px;
                }
                @media #{$sm-layout} {
                    padding: 22px 30px;
                }
                &:hover {
                    background-color: $theme-color-yellow;
                }
            }
            &.product-dec-cart-mrg-none {
                margin: 0 15px 0 0px;
            }
        }
        .product-details-wishlist , .product-details-compare {
            a {
                display: inline-block;
                font-size: 25px;
                color: #2f2f2f;
                &:hover {
                    color: $theme-color-yellow;
                }
            }
        }
        @media #{$xs-layout} {
            .product-details-wishlist {
                &.affiliate-res-xs {
                    margin-bottom: 20px;
                }
            }
        }
        @media #{$sm-layout} {
            .product-details-wishlist {
                &.affiliate-res-xs {
                    margin-bottom: 0px;
                }
            }
        }
        .product-details-compare {
            margin-left: 15px;
            @media #{$xs-layout} {
                &.affiliate-res-xs {
                    margin-left: 0px;
                }
            }
            @media #{$sm-layout} {
                &.affiliate-res-xs {
                    margin-left: 10px;
                }
            }
        }
        &.pro-dec-action-mrg {
            margin: 16px 0 0px;
        }
    }
    .product-details-info {
        border-top: 1px solid #d8d8d8;
        border-bottom: 1px solid #d8d8d8;
        padding: 12px 0 14px;
        a {
            color: #535353;
            font-size: 14px;
            margin-right: 10px;
            &:last-child {
                margin-right: 0;
            }
            i {
                font-size: 16px;
                padding-right: 8px;
                position: relative;
                top: 3px;
            }
            &:hover {
                color: $theme-color-yellow;
            }
        }
    }
    .product-details-meta {
        margin: 25px 0 23px;
        > span {
            display: block;
            font-size: 14px;
            margin: 0 0 5px;
            color: #2f2f2f;
            &.categories {
                a {
                    color: #a59044;
                    &:hover {
                        color: $theme-color-yellow;
                        text-decoration: underline;
                    }
                }
            }
            a { 
                color: #2f2f2f;
                &:hover {
                    color: $theme-color-yellow;
                    text-decoration: underline;
                }
            }
            &:last-child {
                margin: 0 0 0px;
            }
        }
    }
    .product-details-social-wrap {
        display: flex;
        align-items: center;
        margin: 18px 0 0;
        @media #{$xs-layout} {
            display: block;
        }
        @media #{$sm-layout} {
            display: flex;
        }
        span {
            color: #262626;
            font-weight: 600;
            margin-right: 10px;
            font-size: 12px;
        }
        .product-details-social {
            a {
                border: 1px solid #e1e1e1;
                color: #1d1d1d;
                display: inline-block;
                width: 38px;
                height: 38px;
                line-height: 38px;
                border-radius: 100%;
                text-align: center;
                font-size: 16px;
                margin: 0 7px 0 0;
                &:hover {
                    color: #fff;
                    border: 1px solid transparent;
                    &.facebook {
                        background-color: #3b5999;
                    }
                    &.twitter {
                        background-color: #55acee;
                    }
                    &.pinterest {
                        background-color: #b90f16;
                    }
                    &.google-plus {
                        background-color: #dd4b39;
                    }
                }
            }
        }
    }
}
.product-details-quality {
    margin: 7px 0 0;
    @media #{$xs-layout} {
        margin: 0px 0 0;
    }
    .cart-plus-minus {
        display: inline-block;
        height: 60px;
        overflow: hidden;
        padding: 0;
        position: relative;
        width: 85px;
        text-align: center;
        border: 1px solid #1d1d1d;
        .qtybutton {
            color: #181818;
            cursor: pointer;
            float: inherit;
            font-size: 18px;
            line-height: 20px;
            margin: 0;
            position: absolute;
            text-align: center;
            transition: all 0.3s ease 0s;
            width: 24px;
            &.dec {
                height: 58px;
                top: 21px;
                left: 4px;
                font-weight: 400;
                font-size: 22px;
            }
            &.inc {
                height: 58px;
                top: 20px;
                right: 4px;
                font-weight: 400;
                font-size: 22px;
            }
        }
        input {
            &.cart-plus-minus-box {
                background: transparent none repeat scroll 0 0;
                border: medium none;
                color: #282828;
                font-size: 14px;
                height: 60px;
                line-height: 54px;
                margin: 0;
                padding: 0px;
                text-align: center;
                width: 85px;
                font-weight: bold;
            }
        }
    }
    &.pro-quality-border {
        .cart-plus-minus {
            input {
                border: 1px solid rgba(0, 0, 0, 0.1);
            }
        }
    }
    &.quality-width-cart {
        .cart-plus-minus {
            width: 90px;
            border: none;
            input {
                width: 90px;
                font-weight: 400;
            }
            .qtybutton{
                &.dec {
                    left: 0;
                }
                &.inc {
                    right: 0;
                }
            }
        }
    }
}

.quickview-slider-active.owl-carousel {
    > .owl-nav {
        div {
            color: #262626;
            display: inline-block;
            font-size: 24px;
            left: 20px;
            position: absolute;
            text-align: center;
            top: 50%;
            transform: translateY(-50%);
            transition: all 0.3s ease 0s;
            border: 1px solid #e1e2e2;
            background-color: #ffffff;
            display: inline-block;
            width: 50px;
            height: 50px;
            line-height: 50px;
            border-radius: 100%;
            &.owl-next {
                right: 20px;
                left: auto;
            }
            &:hover {
                color: $theme-color-yellow;
            }
        }
    }
}

.easyzoom-style {
    position: relative;
    a.easyzoom-pop-up {
        position: absolute;
        right: 25px;
        bottom: 25px;
        width: 50px;
        height: 50px;
        line-height: 50px;
        display:inline-block;
        background-color: #fff;
        color: #262626;
        font-size: 16px;
        text-align: center;
        border-radius: 100%;
        z-index: 999;
        @media #{$xs-layout} {
            width: 40px;
            height: 40px;
            line-height: 40px;
            font-size: 15px;
        }
        &.video-popup {
            color: #fff;
            font-size: 16px;
            width: 50px;
            height: 50px;
            line-height: 50px;
            background-color: #262626;
        }
        &.img-popup-position {
            bottom: 100px;
        }
        &:hover {
            background-color: $theme-color-yellow;
            color: #fff;
        }
    }
    .easyzoom {
        display: block;
        > a {
            transition: none;
            img {
                width: 100%;
                transition: none;
            } 
        }
    }
    .easyzoom-popup > a img {
        width: 100%;
    }
}
.product-details-tab {
	display: block;
    &.product-details-tab2 {
        overflow: inherit;
    }
}
.product-dec-right {
	float: right;
	position: relative;
	width: calc(100% - 160px);
    @media #{$xx-layout} {
        width: calc(100% - 120px);
    }
    @media #{$xl-layout} {
        width: calc(100% - 120px);
    }
    @media #{$md-layout} {
        width: calc(100% - 130px);
    }
    @media #{$xs-layout} {
        width: calc(100% - 100px);
    }
}
.product-dec-left {
	position: relative;
	float: left;
	width: 160px;
    padding-right: 20px;
    @media #{$xx-layout} {
        width: 120px;
    }
    @media #{$xl-layout} {
        width: 120px;
    }
    @media #{$md-layout} {
        width: 130px;
    }
    @media #{$xs-layout} {
        width: 100px;
    }
    .product-dec-small {
        margin: 0 0 20px;
        cursor: pointer;
        img {
            width: 100%;
        }
        &.slick-active {
            border: 2px solid transparent;
            &.slick-current {
                border: 2px solid #aaa;
            }
        }
        &:last-child {
            margin: 0 0 0px;
        }
    }
    span {
        &.pro-dec-icon {
            position: absolute;
            top: 100%;
            left: 50%;
            right: auto;
            font-size: 20px;
            cursor: pointer;
            color: #262626;
            z-index: 9;
            transform: translateX(-50%);
            @media #{$xs-layout} {
                top: 93%;
            }
            @media #{$sm-layout} {
                top: 100%;
            }
            &:hover {
                color: $theme-color-yellow;
            }
            &.pro-dec-prev {
                margin-left: -30px;
            }
            &.pro-dec-next {
                margin-right: -30px;
            }
        }
    }
}

.product-details-wrap {
    display: flex;
    flex-wrap: wrap;
    .pro-dec-content-left {
        flex: 0 0 72%;
        max-width: 72%;
        padding-right: 50px;
        @media #{$md-layout} {
            padding-right: 30px;
        }
        @media #{$xs-layout} {
            padding-right: 0px;
            flex: 0 0 100%;
            max-width: 100%;
        }
    }
    .pro-dec-content-right {
        flex: 0 0 28%;
        max-width: 28%;
        @media #{$xs-layout} {
            flex: 0 0 100%;
            max-width: 100%;
            margin-top: 30px;
        }
    }
}

.pro-dec-banner-wrap {
    position: relative;
    overflow: hidden;
    a {
        display: block;
        img {
            width: 100%;
            transition: all .5s ease 0s;
            transform: scale(1);
        }
    }
    .pro-dec-banner-content {
        position: absolute;
        left: 20px;
        bottom: 12px;
        z-index: 9;
        @media #{$xl-layout} {
            left: 12px;
        }
        h4 {
            color: #fff;
            font-size: 24px;
            line-height: 1;
            text-transform: uppercase;
            margin: 0 0 10px;
            @media #{$xx-layout} {
                font-size: 20px;
            }
            @media #{$xl-layout} {
                font-size: 16px;
            }
            @media #{$lg-layout} {
                font-size: 18px;
            }
            @media #{$md-layout} {
                font-size: 20px;
            }
            @media #{$xs-layout} {
                font-size: 20px;
            }
        }
        h2 {
            color: #fff;
            font-size: 45px;
            line-height: 1;
            text-transform: uppercase;
            margin: 0;
            @media #{$xx-layout} {
                font-size: 35px;
            }
            @media #{$xl-layout} {
                font-size: 30px;
            }
            @media #{$lg-layout} {
                font-size: 35px;
            }
            @media #{$md-layout} {
                font-size: 35px;
            }
            @media #{$xs-layout} {
                font-size: 35px;
            }
        }
    }
    &:hover {
        a {
            img {
                width: 100%;
                transform: scale(1.1);
            }
        }
    }
}

.sidebar-subscribe-wrap {
    background-color: #E5F3EF;
    padding: 30px 20px 35px 20px;
    margin-top: 20px;
    @media #{$xx-layout} {
        padding: 20px 10px 20px 10px;
    }
    @media #{$xl-layout} {
        padding: 20px 10px 20px 10px;
    }
    @media #{$lg-layout} {
        padding: 20px 10px 20px 10px;
    }
    @media #{$md-layout} {
        padding: 20px 10px 20px 10px;
    }
    .sidebar-subscribe-content {
        i {
            color: #1D1D1D;
            font-size: 30px;
            @media #{$xx-layout} {
                font-size: 25px;
            }
            @media #{$xl-layout} {
                font-size: 25px;
            }
            @media #{$lg-layout} {
                font-size: 25px;
            }
            @media #{$md-layout} {
                font-size: 25px;
            }
        }
        h5 {
            font-size: 22px;
            margin: 1px 0 5px;
            color: #1D1D1D;
            font-family: $playfairdisplay;
            @media #{$xx-layout} {
                font-size: 16px;
            }
            @media #{$xl-layout} {
                font-size: 16px;
            }
            @media #{$lg-layout} {
                font-size: 16px;
            }
            @media #{$md-layout} {
                font-size: 16px;
            }
        }
        p {
            font-size: 14px;
            color: #1D1D1D;
            font-family: $playfairdisplay;
        }
    }
    .sidebar-subscribe-form {
        margin-top: 9px;
        .mc-form {
            > input {
                background-color: transparent;
                border: none;
                color: #777;
                border-bottom: 1px solid #858C8A;
                padding: 2px 0 2px 0;
            }
            .mc-news {
                display: none;
            }
            .clear {
                margin-top: 12px;
                > input {
                    border: none;
                    color: #fff;
                    font-size: 12px;
                    font-weight: bold;
                    background-color: #262626;
                    height: 42px;
                    &:hover {
                        background-color: $theme-color-yellow;
                    }
                }
            }
        }
    }
}

.product-dec-slider , 
.product-dec-small-style2 {
    &.slick-vertical {
        .slick-slide {
            border: 2px solid transparent;
        }
    } 
    .slick-slide {
        border: 2px solid transparent;
    }
} 

.product-dec-col-38 {
	flex: 0 0 34%;
	max-width: 34%;
	position: relative;
	min-height: 1px;
	padding-right: 15px;
	padding-left: 15px;
    @media #{$md-layout} {
        flex: 0 0 100%;
	    max-width: 100%;
    }
    @media #{$xs-layout} {
        flex: 0 0 100%;
	    max-width: 100%;
    }
}
.product-dec-col-62 {
	flex: 0 0 66%;
	max-width: 66%;
	position: relative;
	min-height: 1px;
	padding-right: 15px;
	padding-left: 15px;
    @media #{$md-layout} {
        flex: 0 0 100%;
	    max-width: 100%;
    }
    @media #{$xs-layout} {
        flex: 0 0 100%;
	    max-width: 100%;
    }
}
.description-review-topbar {
    margin-bottom: 70px;
    border-bottom: 3px solid #D8D8D8;
    @media #{$md-layout} {
        margin-bottom: 30px;
    }
    @media #{$xs-layout} {
        margin-bottom: 20px;
    }
    a {
        font-size: 24px;
        color: #BEBEBE;
        line-height: 1;
        text-transform: uppercase;
        margin-right: 70px;
        letter-spacing: .86px;
        position: relative;
        padding-bottom: 33px;
        @media #{$xx-layout} {
            font-size: 20px;
        }
        @media #{$xl-layout} {
            font-size: 20px;
            letter-spacing: 0px;
        }
        @media #{$lg-layout} {
            font-size: 17px;
            letter-spacing: 0px;
        }
        @media #{$md-layout} {
            font-size: 17px;
            letter-spacing: 0px;
            margin-bottom: 20px;
            padding-bottom: 20px;
        }
        @media #{$xs-layout} {
            font-size: 14px;
            letter-spacing: 0px;
            margin-bottom: 10px;
            padding-bottom: 10px;
        }
        &:before {
            position: absolute;
            content: "";
            left: 0;
            bottom: -3px;
            width: 100%;
            height: 10px;
            background-color: transparent;
            @media #{$md-layout} {
                height: 5px;
            }
            @media #{$xs-layout} {
                height: 2px;
            }
        }
        &:last-child {
            margin-right: 0;
        }
        @media #{$xx-layout} {
            margin-right: 30px;
        }
        @media #{$xl-layout} {
            margin-right: 30px;
        }
        @media #{$lg-layout} {
            margin-right: 20px;
        }
        @media #{$md-layout} {
            margin-right: 20px;
        }
        @media #{$xs-layout} {
            margin-right: 10px;
        }
        &.active {
            color: #1D1D1D;
            &:before {
                background-color: #1D1D1D;
            }
        }
    }
}

.pro-details-banner {
    @media #{$md-layout} {
        margin-bottom: 20px;
    }
    @media #{$xs-layout} {
        margin-bottom: 20px;
    }
    overflow: hidden;
    a {
        position: relative;
        img {
            width: 100%;
            transition: all .5s ease 0s;
            transform: scale(1);
        }
    }
    &:hover {
        a {
            img {
                transform: scale(1.1);
            }
        }
    } 
}

.product-dec-content {
    padding-left: 20px;
    @media #{$lg-layout} {
        padding-left: 0px;
    }
    @media #{$md-layout} {
        padding-left: 0px;
    }
    @media #{$xs-layout} {
        padding-left: 0px;
    }
    p {
        line-height: 32px;
        margin: 0;
        font-size: 18px;
        color: #777;
        @media #{$lg-layout} {
            font-size: 16px;
        }
        @media #{$md-layout} {
            font-size: 16px;
        }
        @media #{$xs-layout} {
            font-size: 15px;
            line-height: 28px;
        }
    }
    .product-dec-list-wrap {
        margin: 48px 0 50px;
        display: flex;
        @media #{$lg-layout} {
            margin: 18px 0 10px;
        }
        @media #{$md-layout} {
            margin: 18px 0 10px;
        }
        @media #{$xs-layout} {
            margin: 18px 0 10px;
            display: block;
        }
        .product-dec-list {
            margin-right: 40px;
            @media #{$xs-layout} {
                margin-right: 0px;
            }
            &:last-child {
                margin-right: 0;
            }
            ul {
                list-style-position: inside;
                margin: 0px 0 0px 12px;
                @media #{$lg-layout} {
                    margin: 15px 0 30px 12px;
                }
                @media #{$md-layout} {
                    margin: 15px 0 30px 12px;
                }
                @media #{$xs-layout} {
                    margin: 15px 0 20px 12px;
                }
                li {
                    margin-bottom: 25px;
                    list-style: disc;
                    line-height: 24px;
                    font-size: 18px;
                    color: #777;
                    @media #{$lg-layout} {
                        font-size: 16px;
                        margin-bottom: 20px;
                    }
                    @media #{$md-layout} {
                        font-size: 16px;
                        margin-bottom: 20px;
                    }
                    @media #{$xs-layout} {
                        font-size: 15px;
                        margin-bottom: 12px;
                    }
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}

.additional-info {
    ul {
        li {
            margin-bottom: 20px;
            font-size: 18px;
            color: #777;
            @media #{$xs-layout} {
                font-size: 14px;
            }
            &:last-child {
                margin-bottom: 0;
            }
            span {
                width: 215px;
                @media #{$xs-layout} {
                    width: 70px;
                }
                display: inline-block;
            }
        }
    }
}

.review-wrapper {
    h2 {
        font-size: 30px;
        margin: 0 0 37px;
        @media #{$xs-layout} {
            font-size: 18px;
            margin: 0 0 18px;
        }
    }
}

.single-review {
	margin: 0 0 60px;
	padding: 50px;
	border: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;
    @media #{$xs-layout} {
        padding: 30px;
        display: block;
        margin: 0 0 30px;
    }
    .review-img {
        img {
            border-radius: 100%;
        }
    }
    .review-content {
        margin-left: 50px;
        @media #{$xs-layout} {
            margin-left: 0px;
        }
        .review-top-wrap {
            display: flex;
            justify-content: space-between;
            margin: 0 0 20px;
            @media #{$xs-layout} {
                display: block;
                margin: 10px 0 10px;
            }
            .review-name {
                h5 {
                    font-size: 14px;
                    margin: 0;
                    color: #535353;
                    span {
                        font-weight: bold;
                        font-size: 14px;
                        color: #262626;
                    }
                }
            }
            .review-rating {
                line-height: 1;
                @media #{$xs-layout} {
                    margin: 10px 0 0px;
                }
                i {
                    font-size: 14px;
                    color: #d5d6d8;
                    margin-left: 2px;
                    &.yellow {
                        color: #f5b223;
                    }
                }
            }
        }
        p {
            font-size: 16px;
            color: #777;
            line-height: 1.714286;
            margin: 0;
            @media #{$xs-layout} {
                font-size: 15px;
            }
        }
    }
}

.ratting-form-wrapper {
    > span {
        display: block;
        line-height: 1;
    }
    p {
        font-size: 12px;
        margin: 9px 0 0;
        color: #535353;
        span {
            color: red;
        }
    }
    .ratting-form {
        margin: 30px 0 0;
        .star-box-wrap {
            display: flex;
            margin: 16px 0 29px;
            .single-ratting-star {
                margin-right: 17px;
                display: flex;
                a {
                    color: #535353;
                }
                &:hover i {
                    color: #f5b223;
                }
            }
        }
        .rating-form-style {
            label {
                margin: 0 0 5px;
                color: #535353;
                span {
                    color: red;
                }
            }
            input , textarea {
                background: transparent;
                border: 1px solid #CDCDCD;
                height: 50px;
                padding: 5px 10px;
                &:focus {
                    border: 1px solid #262626;
                }
            }
            textarea {
                height: 150px;
            }
        }
        .form-submit {
            margin-top: 20px;
            input {
                padding: 10px 35px;
                line-height: 30px;
                color: #fff;
                border-width: 0;
                font-weight: 600;
                display: inline-block;
                width: auto;
                height: auto;
                font-size: 14px;
                text-transform: uppercase;
                background: #262626;
                &:hover {
                    background: $theme-color-yellow;
                }
            }
        }
    }
}

.pro-dec-brand-img {
    img {
        width: 140px;
    }
    @media #{$xs-layout} {
        margin: 0 0 20px;
    }
}

.pro-dec-brand-content {
    p {
        margin: 0;
        color: #777;
        font-size: 18px;
        line-height: 34px;
        @media #{$xs-layout} {
            line-height: 30px;
            font-size: 14px;
        }
    }
}

.about-shiping-content {
    p {
        color: #777;
        line-height: 34px;
        font-size: 16px;
        margin-bottom: 30px;
        @media #{$xs-layout} {
            line-height: 30px;
            font-size: 14px;
        }
        &:last-child {
            margin-bottom: 0;
        }
    }
}

@media #{$xs-layout} {
    .description-review-area {
        &.pb-105 {
            padding-bottom: 40px;
        }
    }
}

.product-dec-small-style2 {
    width: 450px;
    margin: 13px auto 0;
    @media #{$xl-layout} {
        width: 400px;
    }
    @media #{$lg-layout} {
        width: 100%;
    }
    @media #{$md-layout} {
        width: 450px;
    }
    @media #{$xs-layout} {
        width: 100%;
    }
    @media #{$sm-layout} {
        width: 450px;
    }
    .product-dec-small {
        margin: 0 5px 0px 5px;
        cursor: pointer;
        img {
            width: 100%;
        }
        &.slick-active {
            border: 2px solid transparent;
            &.slick-current {
                border: 2px solid #262626;
            }
        }
        &:last-child {
            margin: 0 0 0px;
        }
    }
    span {
        &.pro-dec-icon {
            color: #262626;
            font-size: 20px;
            position: absolute;
            line-height: 1;
            top: 50%;
            transform: translateY(-50%);
            cursor: pointer;
            z-index: 9;
            &:hover {
                color: $theme-color-yellow;
            }
        }
        &.pro-dec-prev {
            left: -5%;
            @media #{$lg-layout} {
                left: -3%;
            }
            @media #{$md-layout} {
                left: -4%;
            }
            @media #{$xs-layout} {
                left: -4%;
            }
        }
        &.pro-dec-next {
            left: auto;
            right: -5%;
            @media #{$lg-layout} {
                right: -3%;
            }
            @media #{$md-layout} {
                right: -4%;
            }
            @media #{$xs-layout} {
                right: -4%;
            }
        }
    }
}

.pro-details-group {
    margin: 40px 0 0;
    @media #{$xs-layout} {
        margin: 15px 0 0;
    }
    .single-group {
        display: flex;
        margin-bottom: 10px;
        align-items: center;
        .pro-dec-title-stock {
            margin: 5px 0 0 25px;
            .pro-dec-title {
                h5 {
                    font-size: 14px;
                    margin: 0 0 3px;
                    text-transform: uppercase;
                    color: #262626;
                    a {
                        color: #262626;
                        &:hover {
                            color: $theme-color-yellow;
                        }
                    }
                }
            }
            .pro-dec-price-stock {
                span {
                    color: #262626;
                    font-size: 14px;
                    &.old-price {
                        text-decoration: line-through;
                        margin-left: 5px;
                    }
                }
            }
        }
    }
}

.affiliate-btn {
    margin: 0 20px 0 0px;
    @media #{$lg-layout} {
        margin: 0 20px 5px 0px;
    }
    @media #{$xs-layout} {
        margin: 0 20px 20px 0px;
    }
    @media #{$sm-layout} {
        margin: 0 20px 0 0px;
    }
    a {
        display: inline-block;
        font-size: 14px;
        color: #fff;
        padding: 15px 40px 20px;
        font-weight: 600;
        background-color: #262626;
        @media #{$lg-layout} {
            padding: 20px 26px;
        }
        @media #{$xs-layout} {
            padding: 20px 30px;
        }
        &:hover {
            background-color: $theme-color-yellow;
        }
    }
}

.configurable-wrap {
    margin: 33px 0 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    padding-top: 27px;
    @media #{$lg-layout} {
        margin: 20px 0 0;
    }
    @media #{$xs-layout} {
        margin: 20px 0 0;
    }
    .configurable-color {
        display: flex;
        align-items: center;
        margin: 0 0px 8px 0;
        > span {
            font-size: 14px;
            font-weight: bold;
            margin-right: 20px;
            @media #{$lg-layout} {
                margin-right: 10px;
            }
        }
        ul {
            li {
                display: inline-block;
                margin-right: 3px;
                &:last-child {
                    margin-bottom: 0;
                }
                a {
                    .swatch-anchor {
                        width: 15px;
                        height: 15px;
                        border-radius: 100%;
                        font-size: 0;
                        display: inline-block;
                        position: relative;
                        &::before {
                            content: attr(title);
                            position: absolute;
                            bottom: 80%;
                            text-indent: 0;
                            background: #000;
                            padding: 4px 10px 6px;
                            line-height: 1;
                            border-radius: 4px;
                            margin-bottom: 10px;
                            min-width: 50px;
                            color: #fff;
                            left: 50%;
                            white-space: pre;
                            text-align: center;
                            text-transform: none;
                            transform: translate(-50%, 0);
                            visibility: hidden;
                            opacity: 0;
                            transition: all 250ms ease-out;
                            font-size: 12px;
                        }
                        &::after {
                            content: "";
                            position: absolute;
                            left: 50%;
                            bottom: 80%;
                            border-width: 4px 4px 0 4px;
                            border-style: solid;
                            border-color: #000 transparent transparent transparent;
                            margin-bottom: 6px;
                            margin-left: -4px;
                            visibility: hidden;
                            opacity: 0;
                            transition: all 250ms ease-out;
                        }
                        &:hover::before {
                            bottom: 100%;
                            opacity: 1;
                            visibility: visible;
                        }
                        &:hover::after {
                            bottom: 100%;
                            opacity: 1;
                            visibility: visible;
                        }
                        &.blue {
                            background-color: #5097f7;
                        }
                        &.brown {
                            background-color: #7a5816;
                        }
                        &.green {
                            background-color: #81d742;
                        }
                        &.pink {
                            background-color: #ff0c94;
                        }
                        &.violet {
                            background-color: #8224e3;
                        }
                    }
                }
            }
        }
    }
    .configurable-size {
        display: flex;
        align-items: center;
        > span {
            font-size: 14px;
            font-weight: bold;
            margin-right: 20px;
            @media #{$lg-layout} {
                margin-right: 10px;
            }
        }
        ul {
            li {
                display: inline-block;
                margin-right: 14px;
                @media #{$lg-layout} {
                    margin-right: 5px;
                }
                &:last-child {
                    margin-bottom: 0;
                }
                a {
                    .swatch-anchor {
                        font-size: 14px;
                        display: inline-block;
                        position: relative;
                        color: #262626;
                        &::before {
                            content: attr(title);
                            position: absolute;
                            bottom: 80%;
                            text-indent: 0;
                            background: #000;
                            padding: 4px 10px 6px;
                            line-height: 1;
                            border-radius: 4px;
                            margin-bottom: 10px;
                            min-width: 50px;
                            color: #fff;
                            left: 50%;
                            white-space: pre;
                            text-align: center;
                            text-transform: none;
                            transform: translate(-50%, 0);
                            visibility: hidden;
                            opacity: 0;
                            transition: all 250ms ease-out;
                            font-size: 12px;
                        }
                        &::after {
                            content: "";
                            position: absolute;
                            left: 50%;
                            bottom: 80%;
                            border-width: 4px 4px 0 4px;
                            border-style: solid;
                            border-color: #000 transparent transparent transparent;
                            margin-bottom: 6px;
                            margin-left: -4px;
                            visibility: hidden;
                            opacity: 0;
                            transition: all 250ms ease-out;
                        }
                        &:hover::before {
                            bottom: 100%;
                            opacity: 1;
                            visibility: visible;
                        }
                        &:hover::after {
                            bottom: 100%;
                            opacity: 1;
                            visibility: visible;
                        }
                    }
                }
            }
        }
    }
}

.sidebar-active {
    &.col-xl-6 {
        padding-left: 15px;
        padding-right: 15px;
        &.is-affixed {
            padding-left: 0px;
            padding-right: 0px;
            & .inner-wrapper-sticky {
                padding-left: 15px;
                padding-right: 15px;
            }
        }
    }
}

@media #{$lg-layout} {
    .pro-dec-res-mrg-top {
        margin-top: 50px;
    }
}
@media #{$md-layout} {
    .pro-dec-res-mrg-top {
        margin-top: 30px;
    }
    .pro-dec-res-mrg-top2 {
        margin-top: 30px;
    }
}

@media #{$xs-layout} {
    .pro-dec-res-mrg-top {
        margin-top: 30px;
    }
    .pro-dec-res-mrg-top2 {
        margin-top: 30px;
    }
}




