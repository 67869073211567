/*-------- 23. Sidebar style ---------*/

.sidebar-widget {
    display: block;
    overflow: hidden;
    h4.pro-sidebar-title {
        font-size: 24px;
        margin: 0;
        color: #1D1D1D;
        font-weight: 600;
        text-transform: uppercase;
        letter-spacing: 2px;
        @media #{$md-layout} {
            font-size: 28px;
        }
        @media #{$xs-layout} {
            font-size: 20px;
            letter-spacing: 1px;
        }
    }
    h4.pro-sidebar-title2 {
        font-size: 18px;
        margin: 0;
        color: #535353;
        font-weight: bold;
        text-transform: uppercase;
        letter-spacing: 1px;
        padding-bottom: 5px;
        border-bottom: 2px solid #262626;
        @media #{$xs-layout} {
            font-size: 20px;
            letter-spacing: 1px;
        }
    }
    .sidebar-widget-categories {
        > ul {
            > li {
                display: block;
                margin-bottom: 17px;
                @media #{$xs-layout} {
                    margin-bottom: 12px;
                }
                &:last-child {
                    margin-bottom: 0px;
                }
                > a {
                    color: #1D1D1D;
                    font-size: 18px;
                    font-weight: 600;
                    letter-spacing: 2px;
                    text-transform: uppercase;
                    @media #{$xs-layout} {
                        font-size: 15px;
                        letter-spacing: 1px;
                    }
                    span.menu-expand {
                        margin-left: 12px;
                    }
                }
                ul {
                    margin-top: 20px;
                    margin-bottom: 35px;
                    li {
                        margin-bottom: 11px;
                        &:last-child {
                            margin-bottom: 0px;
                        }
                        a {
                            color: #707070;
                            font-size: 12px;
                            letter-spacing: 1px;
                            &:hover {
                                color: #1D1D1D;
                                text-decoration: underline;
                            }
                        }
                    }
                }
            }
        }
        @media #{$xs-layout} {
            &.mt-50 {
                margin-top: 20px;
            }
        }
    }
    .price-filter{
        #slider-range {
            background: #eee none repeat scroll 0 0;
            border: medium none;
            border-radius: 50px;
            height: 5px;
            margin-bottom: 12px;
            margin-left: auto;
            span {
                transition: all .0s ease 0s;
            }
            .ui-slider-range {
                position: absolute;
                display: block;
                border: 0;
                background: #1D1D1D none repeat scroll 0 0;
                border: none;
                transition: all .0s ease 0s;
            }
            .ui-slider-handle {
                background: #fff none repeat scroll 0 0;
                border: medium none;
                border-radius: 50%;
                height: 20px;
                margin-left: 0;
                width: 20px;
                border: 1px solid #979797;
                top: -8px;
                cursor: ew-resize;
            }
        }
        .price-slider-amount {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 12px 0 0;
            .label-input {
                display: flex;
                align-items: center;
                span {
                    font-size: 14px;
                    margin-right: 5px;
                    color: #1D1D1D !important;
                }
                input {
                    background: transparent;
                    padding: 0;
                    border: none;
                    font-size: 14px;
                    font-weight: 400;
                    width: 60%;
                    height: auto;
                    color: #1D1D1D !important;
                }
            }
            button {
                background-color: transparent;
                padding: 6px 39px;
                border: 2px solid #1D1D1D;
                font-size: 11px;
                font-weight: bold;
                color: #1D1D1D;
                text-transform: uppercase;
                transition: all 0.3s;
                @media #{$lg-layout} {
                    padding: 5px 22px;
                }
                &:hover {
                    background-color: $theme-color-yellow;
                    color: #fff;
                    border: 2px solid $theme-color-yellow;
                }
            }
        }
        @media #{$xs-layout} {
            &.mt-60 {
                margin-top: 30px;
            }
        }
    }
    .sidebar-widget-size , .sidebar-widget-tags {
        ul {
            li {
                display: inline-block;
                margin-right: 8px;
                margin-bottom: 10px;
                a {
                    border: 1px solid #D8D8D8;
                    padding: 13px 20px;
                    display: block;
                    transition: all 0.3s;
                    color: #1D1D1D;
                    @media #{$xx-layout} {
                        padding: 9px 14px;
                    }
                    &.widget-more-content {
                        border: none;
                        color: #C3832D;
                        padding: 13px 10px;
                        &:hover {
                            background-color: transparent;
                            color: $theme-color-yellow;
                            border: none;
                        }
                    }
                    @media #{$lg-layout} {
                        padding: 6px 10px;
                    }
                    &:hover {
                        background-color: $theme-color-yellow;
                        color: #fff;
                        border: 1px solid $theme-color-yellow;
                    }
                }
            }
        }
        @media #{$xs-layout} {
            &.mt-55 {
                margin-top: 25px;
            }
        }
    }
    .sidebar-widget-brand {
        ul {
            li {
                align-items: center;
                position: relative;
                padding: 0px 0 13px;
                &:last-child {
                    padding: 0px 0 0px;
                }
                .sidebar-widget-list-left {
                    position: relative;
                    input {
                        position: absolute;
                        opacity: 0;
                        cursor: pointer;
                        z-index: 999;
                        width: auto;
                        &:checked ~ .checkmark {
                            background-color: $theme-color-yellow;
                            border: 1px solid $theme-color-yellow;
                        }
                        &:checked ~ .checkmark::after {
                            display: block;
                        }
                    }
                    .checkmark {
                        position: absolute;
                        top: 5px;
                        left: 0;
                        height: 16px;
                        width: 16px;
                        background-color: #fff;
                        border: 1px solid #979797;
                        &::after {
                            content: "";
                            position: absolute;
                            display: none;
                        }
                        &::after {
                            left: 5px;
                            top: 2px;
                            width: 5px;
                            height: 8px;
                            border: solid white;
                            border-top-width: medium;
                            border-right-width: medium;
                            border-bottom-width: medium;
                            border-left-width: medium;
                            border-width: 0 2px 2px 0;
                            transform: rotate(45deg);
                        }
                    }
                }
                a {
                    margin-left: 25px;
                    display: block;
                    color: #1D1D1D;
                    letter-spacing: 1px;
                    &.brand-more {
                        color: #C3832D;
                        &:hover {
                            color: $theme-color-yellow;
                        }
                    }
                    &:hover {
                        color: $theme-color-yellow;
                    }
                }
                
            }
        }
        @media #{$xs-layout} {
            &.mt-50 {
                margin-top: 20px;
            }
        }
    }
    .sidebar-widget-color {
        ul {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            li {
                margin-right: 20px;
                margin-bottom: 16px;
                display: inline-block;
                @media #{$xx-layout} {
                    margin-right: 16px;
                    margin-bottom: 12px;
                }
                @media #{$xl-layout} {
                    margin-right: 16px;
                    margin-bottom: 12px;
                }
                &:last-child {
                    margin-right: 0;
                }
                a {
                    width: 30px;
                    height: 30px;
                    border-radius: 100%;
                    font-size: 0;
                    display: inline-block;
                    position: relative;
                    &.color-more {
                        color: #C3832D;
                        font-size: 14px;
                        width: auto;
                        height: auto;
                        letter-spacing: 1px;
                        &:hover {
                            color: $theme-color-yellow;
                        }
                    }
                    &.active::before {
                        color: #fff;
                        content: "\f00c";
                        font-family: FontAwesome;
                        font-size: 14px;
                        position: absolute;
                        z-index: 9;
                        left: 50%;
                        top: 50%;
                        transform: translateY(-50%) translateX(-50%);
                    }
                    &.black {
                        background-color: #1D1D1D;
                    }
                    &.blue {
                        background-color: #6090B6;
                    }
                    &.green {
                        background-color: #75B660;
                    }
                    &.orange {
                        background-color: #E79A9A;
                    }
                    &.pink {
                        background-color: #FF5A9F;
                    }
                    &.gray {
                        background-color: #D8D8D8;
                    }
                    &.white {
                        background-color: #f3f3f3;
                    }
                    &.yellow {
                        background-color: #A59044;
                    }
                    &.violet {
                        background-color: #714DC6;
                    }
                }
            }
        }
        @media #{$xs-layout} {
            &.mt-50 {
                margin-top: 20px;
            }
        }
    }
    .shop-sidebar-banner {
        position: relative;
        overflow: hidden;
        a {
            img {
                width: 100%;
                transform: scale(1);
                transition: all .35s ease 0s;
            }
        }
        .shop-sidebar-content {
            position: absolute;
            left: 20px;
            bottom: 10px;
            width: 100%;
            z-index: 99;
            h5 {
                color: #fff;
                font-size: 32px;
                font-weight: bold;
                margin: 0 0 10px;
                text-transform: uppercase;
                @media #{$xl-layout} {
                    font-size: 25px;
                }
                @media #{$lg-layout} {
                    font-size: 25px;
                }
                @media #{$xs-layout} {
                    font-size: 25px;
                }
            }
            h2 {
                color: #fff;
                font-size: 150px;
                margin: 0 0 0px;
                letter-spacing: 4.69px;
                line-height: 106px;
                @media #{$xx-layout} {
                    font-size: 100px;
                    line-height: 80px;
                }
                @media #{$xl-layout} {
                    font-size: 80px;
                    line-height: 60px;
                }
                @media #{$lg-layout} {
                    font-size: 80px;
                    line-height: 60px;
                }
                @media #{$xs-layout} {
                    font-size: 90px;
                    line-height: 80px;
                }
            }
        }
        &:hover a img {
            transform: scale(1.1);
        }
    }
    .sidebar-post-wrap {
        .single-sidebar-post {
            display: flex;
            border-bottom: 1px solid #DCDCDC;
            padding-bottom: 20px;
            margin-bottom: 20px;
            &:last-child {
                border-bottom: none;
                padding-bottom: 0px;
                margin-bottom: 0px;
            }
            .sidebar-post-img {
                flex: 0 0 100px;
                img {
                    width: 100%;
                }
            }
            .sidebar-post-content {
                margin-left: 20px;
                @media #{$lg-layout} {
                    margin-left: 10px;
                }
                h4 {
                    font-size: 18px;
                    color: #2f2f2f;
                    font-family: $playfairdisplay;
                    margin: 0 0 3px;
                    @media #{$lg-layout} {
                        font-size: 16px;
                    }
                    a {
                        color: #2f2f2f;
                        &:hover {
                            color: $theme-color-yellow;
                        }
                    }
                }
                span {
                    color: #2f2f2f;
                    font-size: 12px;
                }
            }
        }
    }
    .sidebar-widget-list-2 {
        ul {
            li {
                color: #1d1d1d;
                font-family: $playfairdisplay;
                padding-bottom: 20px;
                &:last-child {
                    padding-bottom: 0px;
                }
                a {
                    color: #1d1d1d;
                    &:hover {
                        color: $theme-color-yellow;
                    }
                }
                span {
                    float: right;
                }
            }
        }
    }
    .archives-wrap {
        select {
            -moz-appearance: none;
            -webkit-appearance: none;
            border: 1px solid rgba(0,0,0,0.15);
            color: #535353;
            font-size: 14px;
            height: 50px;
            padding: 2px 20px;
            background: rgba(0, 0, 0, 0) url("../../assets/images/icon-img/select.png") no-repeat scroll right 18px center;
        }
        @media #{$xs-layout} {
            &.mt-40 {
                margin-top: 20px;
            }
        }
    }
    .sidebar-widget-tags-2 {
        ul {
            li {
                display: inline-block;
                margin: 0 0 6px;
                a {
                    color: #535353;
                    &:hover {
                        color: $theme-color-yellow;
                        text-decoration: underline;
                    }
                }
            }
        }
    }
    .blog-sidebar-banner {
        position: relative;
        overflow: hidden;
        a {
            display: block;
            img {
                width: 100%;
                transform: scale(1);
                transition: all .5s ease 0s;
            }
        }
        .blog-sidebar-content {
            position: absolute;
            left: 30px;
            bottom: 50px;
            h3 {
                font-size: 32px;
                font-weight: bold;
                color: #fff;
                line-height: 1;
                margin: 0 0 5px;
            }
            h5 {
                font-size: 16px;
                font-weight: bold;
                color: #fff;
                margin: 0 0 0px;
            }
        }
        &:hover {
            a {
                img {
                    transform: scale(1.1);
                }
            }
        }
    }
    @media #{$md-layout} {
        &.mt-60 {
            margin-top: 35px;
        }
    }
    @media #{$xs-layout} {
        &.mb-60 {
            margin-bottom: 35px;
        }
        &.mb-50 {
            margin-bottom: 25px;
        }
        &.mb-55 {
            margin-bottom: 30px;
        }
        &.mb-65 {
            margin-bottom: 40px;
        }
        &.mt-60 {
            margin-top: 35px;
        }
    }
}

.sidebar-search {
    form {
        position: relative;
        input {
            background-color: transparent;
            color: #262626;
            height: 41px;
            border: 1px solid #cdcdcd;
            padding: 2px 40px 2px 10px;
            font-size: 12px;
            &::-moz-input-placeholder {
                color: #262626;
                opacity: 1;
            }
            &::-webkit-input-placeholder {
                color: #262626;
                opacity: 1;
            }
        }
        .button-search {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 0;
            color: #2f2f2f;
            background-color: transparent;
            border: none;
            padding: 5px 20px;
            transition: all .3s ease 0s;
            &:hover {
                color: $theme-color-yellow;
            }
        }
    }
}

@media #{$xl-layout} {
    .shop-sidebar-style {
        &.mr-50 {
            margin-right: 0;
        }
    }
}
@media #{$lg-layout} {
    .shop-sidebar-style {
        &.mr-50 {
            margin-right: 0;
        }
    }
}
@media #{$md-layout} {
    .shop-sidebar-style {
        margin-top: 50px;
        &.mr-50 {
            margin-right: 80px;
        }
    }
}
@media #{$xs-layout} {
    .shop-sidebar-style {
        margin-top: 50px;
        &.mr-50 {
            margin-right: 0px;
        }
    }
}







