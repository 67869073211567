/*-------- 5. Product style ---------*/

.product-tab-list {
	display: flex;
	justify-content: center;
    a {
        display: inline-block;
        border: 2px solid #d2d2d2;
        border-radius: 2px;
        line-height: 1;
        min-width: 82px;
        text-align: center;
        padding: 8px 10px;
        margin: 0 11px;
        @media #{$md-layout} {
            margin: 0 5px 10px;
        }
        @media #{$xs-layout} {
            margin: 0 4px 8px;
        }
        &.active {
            border: 2px solid #959595;
        }
    }
    &.tab-list-green-2 {
        a {
            &:hover {
                color: $theme-color-green-2;
            }
        }
    }
    @media #{$md-layout} {
        &.mb-60 {
            margin-bottom: 30px;
        }
    }
    @media #{$xs-layout} {
        &.mb-60 {
            margin-bottom: 30px;
        }
    }
}

.product-wrap {
    position: relative;
    .product-img {
        position: relative;
        overflow: hidden;
        &::after {
            border: 8px solid #fff;
            bottom: 10px;
            content: "";
            left: 10px;
            position: absolute;
            right: 10px;
            top: 10px;
            opacity: 0;
            transition: all 0.4s ease-in-out 0s;
            z-index: 9;
            pointer-events: none;
        }
        &.pro-theme-color-border {
            &::after {
                border: 8px solid $theme-color-yellow;
            }
        }
        &.pro-border-none {
            &::after {
                display: none;
            }
        }
        &.pro-border-2 {
            &::before {
                border: 4px solid $theme-color-yellow-2;
                bottom: 10px;
                content: "";
                left: 10px;
                position: absolute;
                right: 10px;
                top: 10px;
                opacity: 0;
                transition: all 0.4s ease-in-out 0s;
                z-index: 9;
                border-radius: 20px;
                pointer-events: none;
            }
        }
        &.pro-border-3 {
            border: 2px solid #f1bfc9;
            border-radius: 20px;
        }
        a {
            img {
                width: 100%;
            }
        }
        > span {
            display: inline-block;
            color: #fff;
            position: absolute;
            letter-spacing: 1.1px;
            line-height: 1;
            z-index: 1;
            padding: 7px 13px 8px;
            &.badge-top-20 {
                top: 32px;
            }
            &.badge-left-20 {
                left: 32px;
            }
            &.badge-right-20 {
                right: 32px;
            }
            &.badge-black {
                background-color: #1b1b1b;
            }
            &.badge-green {
                background-color: #3cd53f;
            }
            &.badge-theme-color {
                background-color: $theme-color-yellow;
            }
            &.badge-style-2 {
                top: 27px;
                right: 3px;
                padding: 0;
                &:before {
                    position: absolute;
                    content: "";
                    top: -27px;
                    z-index: -1;
                    right: -3px;
                    width: 0;
                    height: 0;
                    border-top: 88px solid #ec5555;
                    border-left: 88px solid transparent;
                }
            }
        }
        .product-action-position-1 {
            position: absolute;
            bottom: 32px;
            left: 0;
            right: 0;
            text-align: center;
        }
        .product-action-position-2 {
            position: absolute;
            bottom: 34px;
            left: 0;
            right: 0;
            text-align: center;
            @media #{$lg-layout} {
                bottom: 20px;
            }
            @media #{$xl-layout} {
                bottom: 25px;
            }
        }
        .product-action {
            a {
                display: inline-block;
                font-size: 20px;
                width: 52px;
                height: 52px;
                line-height: 52px;
                text-align: center;
                margin: 0 4px;
                color: #191919;
                background-color: #fff;
                transform: scale(1.2);
                opacity: 0;
                transition: all 0.4s ease-in-out 0s;
                z-index: 99;
                @media #{$xx-layout} {
                    width: 43px;
                    height: 43px;
                    line-height: 43px;
                    font-size: 18px;
                    margin: 0 3px;
                }
                @media #{$xl-layout} {
                    width: 43px;
                    height: 43px;
                    line-height: 43px;
                    font-size: 18px;
                    margin: 0 2px;
                }
                @media #{$lg-layout} {
                    width: 48px;
                    height: 48px;
                    line-height: 48px;
                    margin: 0 2px;
                }
                @media #{$md-layout} {
                    width: 48px;
                    height: 48px;
                    line-height: 48px;
                    margin: 0 2px;
                }
                @media #{$xs-layout} {
                    width: 40px;
                    height: 40px;
                    line-height: 40px;
                    font-size: 18px;
                    margin: 0 2px;
                }
                i {
                    line-height: 52px;
                    transition: all 0.4s ease-in-out 0s;
                    @media #{$xx-layout} {
                        line-height: 43px;
                    }
                    @media #{$xl-layout} {
                        line-height: 43px;
                    }
                    @media #{$lg-layout} {
                        line-height: 48px;
                    }
                    @media #{$md-layout} {
                        line-height: 48px;
                    }
                    @media #{$xs-layout} {
                        line-height: 40px;
                    }
                }
                &:hover {
                    background-color: #191919;
                    color: #fff;
                }
            }
            &.pro-action-col4 {
                a {
                    font-size: 18px;
                    width: 40px;
                    height: 40px;
                    line-height: 40px;
                    margin: 0 2px;
                    i {
                        line-height: 40px;
                    }
                }
            }
            &.pro-action-theme-color {
                a {
                    background-color: $theme-color-yellow;
                    color: #fff;
                    &:hover {
                        background-color: #191919;
                    }
                }
            }
            &.pro-action-yellow-2 {
                a {
                    background-color: $theme-color-yellow-2;
                    color: #fff;
                    &:hover {
                        background-color: #191919;
                    }
                }
            }
            &.pro-action-pink {
                a {
                    background-color: $theme-color-pink;
                    color: #fff;
                    &:hover {
                        background-color: #191919;
                    }
                }
            }
            &.pro-action-green3 {
                a {
                    background-color: $theme-color-green-3;
                    color: #fff;
                    &:hover {
                        background-color: #191919;
                    }
                }
            }
        }
        .product-action-2 {
            a {
                color: #191919;
                display: inline-block;
                font-size: 20px;
                opacity: 0;
                transform: translateY(15px);
                margin: 0 14px;
                visibility: hidden;
                @media #{$xx-layout} {
                    margin: 0px 10px;
                }
                @media #{$xl-layout} {
                    margin: 0px 8px;
                }
                @media #{$lg-layout} {
                    margin: 0px 8px;
                }
                &:nth-child(1) {
                    transition-delay: 150ms;
                }
                &:nth-child(2) {
                    transition-delay: 200ms;
                }
                &:nth-child(3) {
                    transition-delay: 250ms;
                }
                &:nth-child(4) {
                    transition-delay: 300ms;
                }
                &:hover {
                    color: $theme-color-green;
                }
            }
            &.pro-action-yellow-2 {
                a {
                    &:hover {
                        color: $theme-color-yellow-2;
                    }
                }
            }
        }
        .deal-count {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 20px;
            text-align: center;
            width: 100%;
            div {
                display: flex;
                justify-content: center;
                > span {
                    background-color: #262626;
                    color: #fff;
                    margin: 0 5px;
                    padding: 4px 9px 13px;
                    @media #{$sm-layout} {
                        padding: 4px 6px 13px;
                        margin: 0 3px;
                    }
                    p {
                       color: #fff;
                        margin: 0;
                        display: block;
                        line-height: 1;
                    }
                }
            }
        }
        .shop-list-quickview {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translateY(-50%) translateX(-50%);
            a {
                display: inline-block;
                font-size: 20px;
                width: 52px;
                height: 52px;
                line-height: 52px;
                text-align: center;
                margin: 0 4px;
                color: #fff;
                background-color: $theme-color-yellow;
                transform: scale(1.2);
                opacity: 0;
                transition: all 0.4s ease-in-out 0s;
                z-index: 99;
                i {
                    line-height: 52px;
                    transition: all 0.4s ease-in-out 0s;
                }
                &:hover {
                    background-color: #191919;
                    color: #fff;
                }
            }
        }
        &.pro-img-border-radius {
            border-radius: 15px;
        }
        @media #{$xs-layout} {
            &.mb-25 {
                margin-bottom: 12px;
            }
        }
    }
    .product-content {
        h4 {
            font-size: 14px;
            margin: 0 0 7px;
            a {
                
            }
        }
        .product-price {
            span {
                position: relative;
                &.old-price {
                    margin-right: 4px;
                    &:before {
                        position: absolute;
                        content: "";
                        width: 20px;
                        height: 2px;
                        top: 50%;
                        left: 50%;
                        transform: translateX(-50%) translateY(-50%) rotate(130deg);
                        background-color: #393939;
                    }
                }
            }
        }
        &.product-content-green-2 {
            h4 {
                a {
                    color: #393939;
                    &:hover {
                        color: $theme-color-green-2;
                    }
                }
            }
        }
        &.product-content-yellow-2 {
            h4 {
                a {
                    color: #393939;
                    &:hover {
                        color: $theme-color-yellow-2;
                    }
                }
            }
        }
        &.product-content-pink {
            h4 {
                a {
                    color: #393939;
                    &:hover {
                        color: $theme-color-pink;
                    }
                }
            }
        }
        &.product-content-green3 {
            h4 {
                a {
                    color: #393939;
                    &:hover {
                        color: $theme-color-green-3;
                    }
                }
            }
        }
    }
    .product-content-2 {
        h4 {
            font-size: 24px;
            font-weight: bold;
            color: #1b1b1b;
            font-family: $rufina;
            margin: 0 0 22px;
            @media #{$xl-layout} {
                font-size: 20px;
            }
            @media #{$lg-layout} {
                font-size: 20px;
                margin: 0 0 12px;
            }
            @media #{$md-layout} {
                font-size: 20px;
                margin: 0 0 12px;
            }
            @media #{$xs-layout} {
                font-size: 20px;
                margin: 0 0 7px;
            }
            a {
                color: #1b1b1b;
                &:hover {
                    color: $theme-color-yellow;
                }
            }
        }
        > span {
            display: block;
            margin: 0 0 8px;
            a {
                font-weight: 600;
                color: #1b1b1b;
                font-style: italic;
                display: block;
                &:hover {
                    color: $theme-color-yellow;
                }
            }
        }
        .product-rating-wrap {
            display: block;
            margin: 17px 0 6px;
            @media #{$lg-layout} {
                margin: 10px 0 6px;
            }
            @media #{$md-layout} {
                margin: 10px 0 6px;
            }
            @media #{$xs-layout} {
                margin: 7px 0 6px;
            }
            .product-rating {
                background-color: #fff;
                display: inline-block;
                padding: 2px;
                i {
                    color: #f6c443;
                    font-size: 15px;
                }
            }
        }
        .product-price-2 {
            span {
                font-weight: 600;
                color: $theme-color-yellow;
                &.old-price {
                    color: #1b1b1b;
                    margin-right: 4px;
                    position: relative;
                    &::before {
                        position: absolute;
                        content: "";
                        width: 20px;
                        height: 2px;
                        top: 50%;
                        left: 50%;
                        transform: translateX(-50%) translateY(-50%) rotate(130deg);
                        background-color: #393939;
                    }
                }
            }
        }
        &.pc-2-font-dec {
            h4 {
                font-size: 20px;
                margin: 0 0 13px;
                @media #{$xs-layout} {
                    margin: 0 0 8px;
                }
            }
        }
    }
    .product-content-3 {
        h4 {
            font-size: 18px;
            font-family: $fredokaone;
            margin: 0 0 3px;
        }
        .product-price-3 {
            span {
                font-size: 18px;
                font-family: $fredokaone;
                color: $theme-color-yellow;
                &.old-price {
                    color: #8b8b8b;
                    margin-right: 4px;
                    position: relative;
                    &::before {
                        position: absolute;
                        content: "";
                        width: 25px;
                        height: 2px;
                        top: 50%;
                        left: 50%;
                        transform: translateX(-50%) translateY(-50%) rotate(130deg);
                        background-color: #1b1b1b;
                    }
                }
            }
        }
    }
    .product-img-zoom {
        a {
            img {
                width: 100%;
                transform: scale(1);
                transition: all .5s ease 0s;
            }
        }
    }
    .product-banner-content {
        position: absolute;
        bottom: 55px;
        left: 0;
        right: 0;
        width: 100%;
        text-align: center;
        @media #{$xs-layout} {
            bottom: 30px;
        }
        span {
            display: block;
            margin: 0 0 6px;
        }
        h4 {
            background-color: #c74040;
            color: #fff;
            margin: 0;
            line-height: 1;
            font-size: 36px;
            display: inline-block;
            padding: 12px 40px 10px;
            @media #{$md-layout} {
                font-size: 25px;
                padding: 10px 35px 8px;
            }
            @media #{$xs-layout} {
                font-size: 25px;
                padding: 8px 30px 5px;
            }
        }
    }
    .product-banner-content-2 {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        right: 0;
        width: 100%;
        text-align: center;
        .clutch {
            span {
                display: inline-block;
                background-color: #f2c456;
                line-height: 1;
                padding: 9px 12px 14px;
                @media #{$xs-layout} {
                    font-size: 12px;
                }
            }
        }
        h2 {
            color: #fff;
            margin: 15px 0 32px;
            line-height: 1;
            font-size: 80px;
            @media #{$xl-layout} {
                font-size: 60px;
                margin: 15px 0 25px;
            }
            @media #{$md-layout} {
                font-size: 68px;
                margin: 15px 0 25px;
            }
            @media #{$xs-layout} {
                font-size: 30px;
                margin: 12px 0 15px;
            }
            @media #{$sm-layout} {
                font-size: 38px;
                margin: 18px 0 18px;
            }
        }
    }
    .product-border {
        border: 2px solid #e4e4e4;
    }
    &.product-wrap-hover {
        transition: all .3s ease 0s;
        &:hover {
            margin-top: -28px;
            
        }
    }
    &:hover {
        .product-img {
            &::after {
                right: 24px;
                left: 24px;
                top: 24px;
                bottom: 24px;
                opacity: 1;
            }
            &.pro-border-2 {
                &::before {
                    right: 0px;
                    left: 0px;
                    top: 0px;
                    bottom: 0px;
                    opacity: 1;
                }
            }
        }
        .product-img-zoom {
            a {
                img {
                    transform: scale(1.2);
                }
            }
        }
        .product-action {
            a {
                transform: scale(1);
                opacity: 1;
            }
        }
        .product-action-2 {
            a {
                opacity: 1;
                visibility: visible;
                transform: translateY(0px);
            }
        }
    }
    @media #{$md-layout} {
        &.mb-55 {
            margin-bottom: 30px;
        }
    }
    @media #{$xs-layout} {
        &.mb-55 {
            margin-bottom: 30px;
        }
    }
}

.load-btn-style-1 {
    a {
        span {
            display: block;
        }
        &:hover {
            color: #000;
        }
    }
}

.single-collection-wrap {
    position: relative;
    padding-left: 15px;
    padding-right: 15px;
    @media #{$md-layout} {
        padding-left: 40px;
        padding-right: 40px;
    }
    .collection-img {
        a {
            img {
                opacity: .3;
                max-width: 100%;
            }
        }
    }
    .collection-content-wrap {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 615px;
        @media #{$xx-layout} {
            left: 490px;
        }
        @media #{$xl-layout} {
            left: 352px;
        }
        @media #{$lg-layout} {
            left: 379px;
        }
        @media #{$md-layout} {
            position: static;
            left: 0px;
            top: inherit;
            transform: inherit;
            margin-top: 20px;
        }
        @media #{$xs-layout} {
            position: static;
            left: 15px;
            top: inherit;
            transform: inherit;
            margin-top: 20px;
        }
    }
    &.slick-current {
        .collection-content-wrap {
            direction: ltr;
            text-align: left;
            visibility: visible;
            opacity: 1;
            @media #{$xs-layout} {
                direction: inherit;
            }
        }
        .collection-img {
            direction: ltr !important;
            float: left;
            @media #{$xs-layout} {
                direction: inherit;
                float: inherit;
            }
            a {
                img {
                    opacity: 1;
                    
                }
            }
        }
    }
}
.collection-content-wrap {
    width: 724px;
    background: #ffffff;
    box-shadow: 0px 0px 20px rgba(0,0,0,0.2);
    z-index: 99;
    display: flex;
    align-items: center;
    padding: 138px 105px;
    visibility: hidden;
    opacity: 0;
    @media #{$xx-layout} {
        padding: 50px 50px;
        width: 600px;
    }
    @media #{$xl-layout} {
        padding: 70px 50px;
        width: 600px;
    }
    @media #{$lg-layout} {
        padding: 70px 50px;
        width: 500px;
    }
    @media #{$md-layout} {
        padding: 70px 50px;
        width: 100%;
    }
    @media #{$xs-layout} {
        padding: 30px 30px;
        width: 100%;
        visibility: visible;
        opacity: 1;
    }
}

.collection-content {
    h2 {
        font-size: 32px;
        font-weight: 500;
        margin: 0;
        position: relative;
        padding-left: 122px;
        &:before {
            position: absolute;
            width: 100px;
            height: 3px;
            left: 0;
            bottom: 18px;
            content: "";
            background-color: #1b1b1b;
            @media #{$xs-layout} {
                width:40px;
            }
            @media #{$sm-layout} {
                bottom: 8px;
            }
        }
        @media #{$lg-layout} {
            font-size: 22px;
        }
        @media #{$md-layout} {
            font-size: 22px;
        }
        @media #{$xs-layout} {
            font-size: 17px;
            padding-left: 50px;
        }
    }
    p {
        font-size: 18px;
        margin: 52px 0 33px;
        line-height: 36px;
        @media #{$xx-layout} {
            margin: 15px 0 26px;
        }
        @media #{$xl-layout} {
            margin: 15px 0 26px;
        }
        @media #{$lg-layout} {
            margin: 15px 0 26px;
        }
        @media #{$md-layout} {
            margin: 15px 0 26px;
        }
        @media #{$xs-layout} {
            margin: 8px 0 15px;
            font-size: 15px;
            line-height: 26px;
        }
    }
}

.btn-style-2 {
    a {
        font-size: 16px;
        color: #1b1b1b;
        display: inline-block;
        line-height: 1;
        border: 2px solid #858585;
        padding: 20px 37px 24px;
        transition: all .5s ease-in-out 0s;
        z-index: 9;
        @media #{$xs-layout} {
            padding: 12px 20px 14px;
            font-size: 15px;
        }
        &:hover {
            border: 2px solid #000;
        }
    }
}

.collection-slider-active {
    overflow: hidden;
    .slick-list {
        padding-left: 475px;
        margin-right: -15px;
        margin-left: -15px;
        @media #{$xx-layout} {
            padding-left: 250px;
        }
        @media #{$xl-layout} {
            padding-left: 250px;
        }
        @media #{$lg-layout} {
            padding-left: 100px;
        }
        @media #{$md-layout} {
            padding-left: 0px;
            margin-right: 0px;
            margin-left: 0px;
        }
        @media #{$xs-layout} {
            padding-left: 0px;
            margin-right: 0px;
            margin-left: 0px;
        }
    }
}

.collection-icon-next {
	position: absolute;
	font-size: 77px;
	bottom: 47px;
	right: 32%;
	cursor: pointer;
    @media #{$xl-layout} {
        right: 41%;
        bottom: 140px;
    }
    @media #{$lg-layout} {
        right: 41%;
        bottom: 152px;
    }
    @media #{$md-layout} {
        font-size: 55px;
        bottom: 65%;
        right: 50%;
        transform: translateY(50%) translateX(50%);
    }
    @media #{$xs-layout} {
        font-size: 55px;
        bottom: 52%;
        right: 50%;
        transform: translateY(50%) translateX(50%);
    }
    @media #{$sm-layout} {
        bottom: 71%;
    }
    &:hover {
        color: #666;
    }
}

.deal-all-pro {
    a {
        color: $theme-color-yellow;
        &:hover {
            color: #393939;
        }
    }
}

.deal-ptb {
    padding: 82px 115px 83px;
    @media #{$lg-layout} {
        padding: 82px 30px 83px;
    }
    @media #{$md-layout} {
        padding: 42px 40px 43px;
    }
    @media #{$xs-layout} {
        padding: 42px 15px 43px;
    }
}

.deal-ntv-mrg {
    margin-bottom: -67px;
    position: relative;
    z-index: 2;
}

.product-tab-list-2 {
	display: flex;
	justify-content: center;
    a {
        display: inline-block;
        line-height: 1;
        margin: 0 34px;
        color: #aeaeae;
        font-size: 32px;
        font-weight: 500;
        padding: 0 0 21px;
        position: relative;
        @media #{$md-layout} {
            margin: 0 15px;
            font-size: 26px;
        }
        @media #{$xs-layout} {
            padding: 0 0 8px;
            font-size: 16px;
            margin: 0 10px 15px;
        }
        @media #{$sm-layout} {
            font-size: 20px;
        }
        &:before {
            position: absolute;
            content: "";
            left: 0;
            right: 0;
            margin: 0 auto;
            width: 100px;
            height: 2px;
            bottom: 0;
            background-color: transparent;
            @media #{$xs-layout} {
                width: 50px;
            }
        }
        &.active {
            color: #1b1b1b;
            &:before {
                background-color: $theme-color-yellow;
            }
        }
    }
    @media #{$xs-layout} {
        &.mb-60 {
            margin-bottom: 10px;
        }
    }
}
.product-book-slider {
    overflow: hidden;
    .slick-list {
        padding: 0 370px;
        margin-right: -30px;
	    margin-left: -30px;
        @media #{$xx-layout} {
            padding: 0 200px;
        }
        @media #{$xl-layout} {
            padding: 0 120px;
        }
        @media #{$lg-layout} {
            padding: 0 150px;
        }
        @media #{$md-layout} {
            padding: 0 80px;
            margin-right: -15px;
	        margin-left: -15px;
        }
        @media #{$xs-layout} {
            padding: 0 20px;
            margin-right: -15px;
	        margin-left: -15px;
        }
        @media #{$sm-layout} {
            padding: 0 30px;
        }
    }
    .product-plr-opacity {
        padding-left: 30px;
	    padding-right: 30px;
        opacity: 0.3;
        @media #{$md-layout} {
            padding-left: 15px;
	        padding-right: 15px;
        }
        @media #{$xs-layout} {
            padding-left: 15px;
	        padding-right: 15px;
        }
        &.slick-active {
            opacity: 1;
        }
    }
}
.new-book-slider {
    overflow: hidden;
    .slick-list {
        padding: 0 330px 0 0;
        margin-right: -30px;
	    margin-left: -30px;
        @media #{$xx-layout} {
            padding: 0 150px 0 0;
        }
        @media #{$xl-layout} {
            padding: 0 150px 0 0;
        }
        @media #{$lg-layout} {
            padding: 0 0px 0 0;
            margin-right: -15px;
	        margin-left: -15px;
        }
        @media #{$md-layout} {
            padding: 0 90px 0 0;
            margin-right: -15px;
	        margin-left: -15px;
        }
        @media #{$xs-layout} {
            padding: 0 15px 0 0;
            margin-right: -15px;
	        margin-left: -15px;
        }
    }
    .product-plr-2 {
        padding-left: 30px;
	    padding-right: 30px;
        @media #{$lg-layout} {
            padding-left: 15px;
	        padding-right: 15px;
        }
        @media #{$md-layout} {
            padding-left: 15px;
	        padding-right: 15px;
        }
        @media #{$xs-layout} {
            padding-left: 15px;
	        padding-right: 15px;
        }
    }
}

.newbooks-pl {
    padding-left: 375px;
    @media #{$xx-layout} {
        padding-left: 250px;
    }
    @media #{$xl-layout} {
        padding-left: 15px;
    }
    @media #{$lg-layout} {
        padding-left: 30px;
    }
    @media #{$md-layout} {
        padding-left: 40px;
    }
    @media #{$xs-layout} {
        padding-left: 15px;
    }
}

.dot-style-2 {
    .slick-dots {
        margin-top: 30px;
        @media #{$xs-layout} {
            margin-top: 10px;
        }
        li {
            display: inline-block;
            button {
                background-color: #d8d8d8;
                width: 100px;
                height: 8px;
                display: inline-block;
                margin: 0 10px 0 0;
                transition: all .3s ease 0s;
                padding: 0;
                border: none;
                font-size: 0;
                @media #{$xs-layout} {
                    width: 50px;
                }
                &:hover {
                    background-color: $theme-color-yellow;
                }
            }
            &.slick-active {
                button {
                    background-color: $theme-color-yellow;
                }
            }
        }
    }
} 

.collection-img-2 {
    overflow: hidden;
    a {
        display: block;
        img {
            width: 100%;
            transform: scale(1);
            transition: all .5s ease 0s;
        }
    }
    &:hover {
        a {
            img {
                transform: scale(1.2);
            }
        }
    }
    @media #{$xx-layout} {
        &.mb-37 {
            margin-bottom: 24px;
        }
    }
    @media #{$xl-layout} {
        &.mb-37 {
            margin-bottom: 20px;
        }
    }
    @media #{$lg-layout} {
        &.mb-37 {
            margin-bottom: 15px;
        }
    }
    @media #{$md-layout} {
        &.mb-37 {
            margin-bottom: 17px;
        }
    }
    @media #{$xs-layout} {
        &.mb-37 {
            margin-bottom: 15px;
        }
    }
}

.flower-best-choice-pl {
    padding-left: 330px;
    @media #{$xx-layout} {
        padding-left: 200px;
    }
    @media #{$xl-layout} {
        padding-left: 100px;
    }
    @media #{$lg-layout} {
        padding-left: 50px;
    }
    @media #{$md-layout} {
        padding-left: 40px;
    }
    @media #{$xs-layout} {
        padding-left: 15px;
    }
}

.flower-best-choice-area {
    background: linear-gradient(to right, #e1f4f9, #e1f4f9 84%, #e1f4f9 16%, #fff 0%);
}

.single-flower-best-choice {
    position: relative;
    .flower-best-img {
        
    }
    .flower-best-content {
        position: absolute;
        left: 45px;
        bottom: 50px;
        @media #{$xs-layout} {
            left: 15px;
            bottom: 10px;
        }
        h3 {
            font-size: 46px;
            font-weight: bold;
            font-family: $playfairdisplay;
            font-style: italic;
            margin: 0;
            color: #fff;
            @media #{$xs-layout} {
                font-size: 30px;
            }
            a {
                color: #fff;
                &:hover {
                    color: $theme-color-green;
                }
            }
        }
    }
}

.flower-best-choice-slider {
    overflow: hidden;
    .slick-list {
        padding-right: 520px;
        margin-right: -74px;
        margin-left: -74px;
        @media #{$xx-layout} {
            padding-right:300px;
        }
        @media #{$xl-layout} {
            padding-right: 200px;
            margin-right: -25px;
            margin-left: -25px;
        }
        @media #{$lg-layout} {
            padding-right: 100px;
            margin-right: -25px;
            margin-left: -25px;
        }
        @media #{$md-layout} {
            padding-right: 150px;
            margin-right: -25px;
            margin-left: -25px;
        }
        @media #{$xs-layout} {
            padding-right: 80px;
            margin-right: -15px;
            margin-left: -15px; 
        }
        .single-flower-best-plr {
            padding-left: 74px;
            padding-right: 74px;
            @media #{$xl-layout} {
                padding-left: 25px;
                padding-right: 25px;
            }
            @media #{$lg-layout} {
                padding-left: 25px;
                padding-right: 25px;
            }
            @media #{$md-layout} {
                padding-left: 25px;
                padding-right: 25px;
            }
            @media #{$xs-layout} {
                padding-left: 15px;
                padding-right: 15px;
            }
            .single-flower-best-choice {
                .flower-best-content {
                    h3 {
                        display: none;
                    }
                }
            }
            &.slick-active {
                .single-flower-best-choice {
                    .flower-best-content {
                        h3 {
                            display: block;
                        }
                    }
                }
            }
        }
    }
}

.slick-slide {
    .single-flower-best-choice {
        .flower-best-img {
            a {
                img {
                    width: 100%;
                }
            }
        }
    }
}

.flower-best-choice-icon-next {
	position: absolute;
	font-size: 77px;
	bottom: -12px;
	left: 54%;
	cursor: pointer;
    @media #{$xx-layout} {
        left: 62%;
    }
    @media #{$xl-layout} {
        left: 71%;
    }
    @media #{$lg-layout} {
        left: 81%;
        font-size: 70px;
    }
    @media #{$md-layout} {
        left: 75%;
        font-size: 60px;
    }
    @media #{$xs-layout} {
        left: 65%;
        font-size: 53px;
    }
    @media #{$sm-layout} {
        left: 81%;
    }
    &:hover {
        color: $theme-color-green;
    }
}

.single-collection-wrap-2 {
    position: relative;
    > a {
        img {
            width: 100%;
        }
        &:before {
            position: absolute;
            content: "";
            left: 0;
            right: 0;
            top: 0;
            height: 100%;
            width: 100%;
            background-color: #52e3b9;
            opacity: 0;
            visibility: hidden;
            transition: all .3s ease 0s;
        }
    }
    .collection-content-wrap-2 {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        right: 0;
        text-align: center;
        h3 {
            font-size: 36px;
            color: #1b1b1b;
            font-weight: bold;
            margin: 0 0 0 -50px;
            position: relative;
            display: inline-block;
            padding: 21px 0 27px;
            font-family: $playfairdisplay;
            transition: all .4s ease 0s;
            opacity: 0;
            visibility: hidden;
            @media #{$xx-layout} {
                font-size: 30px;
                padding: 18px 0 24px;
            }
            @media #{$xl-layout} {
                font-size: 28px;
                padding: 14px 0 20px;
            }
            @media #{$lg-layout} {
                font-size: 25px;
                padding: 14px 0 20px;
            }
            @media #{$md-layout} {
                font-size: 25px;
                padding: 11px 0 17px;
            }
            @media #{$xs-layout} {
                font-size: 25px;
                padding: 11px 0 17px;
            }
            @media #{$sm-layout} {
                font-size: 20px;
            }
            a {
                color: #1b1b1b;
                &:hover {
                    color: #444;
                }
            }
            &:before {
                position: absolute;
                left: 0px;
                top: -30px;
                height: 2px;
                width: 100%;
                background-color: #1b1b1b;
                content: "";
                transition: all .6s ease 0s;
                opacity: 0;
                visibility: hidden;
            }
            &:after {
                position: absolute;
                left: 0px;
                bottom: -30px;
                height: 2px;
                width: 100%;
                background-color: #1b1b1b;
                content: "";
                transition: all .6s ease 0s;
                opacity: 0;
                visibility: hidden;
            }
        }
    }
    &:hover {
        > a:before {
            opacity: .9;
            visibility: visible;
        }
        .collection-content-wrap-2 {
            h3 {
                opacity: 1;
                visibility: visible;
                margin: 0 0 0 0px;
                &:before {
                    top: 0px;
                    opacity: 1;
                    visibility: visible;
                }
                &:after {
                    bottom: 0px;
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
    }
}

@media #{$md-layout} {
    .product-area {
        &.pt-75 {
            padding-top: 45px;
        }
    }
}

@media #{$xs-layout} {
    .deal-area{
        &.pt-90 {
            padding-top: 50px;
        }
    }
    .product-area {
        &.pt-75 {
            padding-top: 28px;
        }
        &.pb-60 {
            padding-bottom: 30px;
        }
    }
    .pets-product-area {
        &.pb-55 {
            padding-bottom: 0px;
        }
    }
}

.furniture-deal-area {
    box-shadow: 0px 0px 23px rgba(0, 0, 0, 0.2);
}
.deal-slider-active {
    padding: 0 185px;
    @media #{$xx-layout} {
        padding: 0 120px;
    }
    @media #{$xl-layout} {
        padding: 0 80px;
    }
    @media #{$lg-layout} {
        padding: 0 50px;
    }
    @media #{$md-layout} {
        padding: 0 50px;
    }
    @media #{$xs-layout} {
        padding: 0 15px;
    }
}

.kids-pro-top-wrap {
    position: relative;
    z-index: 9;
    overflow: hidden;
    background-color:#fff;
    border-radius: 50px;
    &::after {
        border: 16px solid #000;
        bottom: 30px;
        content: "";
        left: 30px;
        position: absolute;
        right: 30px;
        top: 30px;
        z-index: 9;
        pointer-events: none;
        border-radius: 50px;
        @media #{$md-layout} {
            bottom: 15px;
            left: 15px;
            right: 15px;
            top: 15px;
        }
        @media #{$xs-layout} {
            bottom: 15px;
            left: 15px;
            right: 15px;
            top: 15px;
        }
    }
    a {
        img {
            width: 100%;
            transform: scale(1);
            transition: all .5s ease 0s;
        }
    }
    .kids-pro-top-content {
        position: absolute;
        bottom: 22%;
        left: 0;
        right: 0;
        text-align: center;
        padding: 0 75px;
        @media #{$xx-layout} {
            bottom: 13%;
            padding: 0 53px;
        }
        @media #{$xl-layout} {
            bottom: 15%;
            padding: 0 52px;
        }
        @media #{$lg-layout} {
            bottom: 15%;
        }
        @media #{$md-layout} {
            bottom: 10%;
            padding: 0 52px;
        }
        @media #{$xs-layout} {
            bottom: 10%;
            padding: 0 52px;
        }
        h3 {
            font-size: 20px;
            color: #1b1b1b;
            @media #{$xx-layout} {
                font-size: 16px;
                line-height: 24px;
            }
            @media #{$xl-layout} {
                font-size: 14px;
                line-height: 22px;
            }
            @media #{$lg-layout} {
                font-size: 16px;
                line-height: 24px;
            }
            @media #{$md-layout} {
                font-size: 16px;
                line-height: 24px;
            }
            @media #{$xs-layout} {
                font-size: 14px;
                line-height: 24px;
            }
            @media #{$sm-layout} {
                font-size: 16px;
            }
            a {
                color: #1b1b1b;
                &:hover {
                    color: $theme-color-yellow-2;
                }
            }
        }
    }
    &.pro-border-yellow {
        &::after {
            border: 16px solid $theme-color-yellow-2;
        }
    }
    &.pro-border-green {
        &::after {
            border: 16px solid #3ad9b1;
        }
    }
    &.pro-border-pink {
        &::after {
            border: 16px solid #fd76e5;
        }
    }
    &:hover {
        > a {
            img {
                transform: scale(1.1);
            }
        }
    }
}

.product-area-negative-mrg {
	margin-top: -62px;
}

.product-slider-1 {
    z-index: 9;
}

.deal-area {
    position: relative;
    overflow: hidden;
    .kids-pattern-1 {
        position: absolute;
        top: 9px;
        left: 68px;
        animation-iteration-count: infinite;
        animation-name: bounce-img-2;
        animation-duration: 2.5s;
        @media #{$lg-layout} {
            top: 20px;
            left: 0px;
        }
        @media #{$md-layout} {
            top: 20px;
            left: 0px;
        }
        @media #{$xs-layout} {
            top: 20px;
            left: auto;
            right: 0;
        }
        img {
            width: 249px;
            @media #{$lg-layout} {
                width: 100px;
            }
            @media #{$md-layout} {
                width: 100px;
            }
            @media #{$xs-layout} {
                width: 100px;
            }
        }
    }
    .kids-pattern-2 {
        position: absolute;
        top: 70px;
        right: 44%;
        animation-iteration-count: infinite;
        animation-name: bounce-img-2;
        animation-duration: 2.5s;
        img {
            width: 148px;
            @media #{$lg-layout} {
                width: 100px;
            }
            @media #{$md-layout} {
                width: 100px;
            }
            @media #{$xs-layout} {
                width: 100px;
            }
        }
    }
    .kids-pattern-3 {
        position: absolute;
        top: 29%;
        right: 0%;
        animation-iteration-count: infinite;
        animation-name: bounce-img-2;
        animation-duration: 2.5s;
        img {
            width: 262px;
            @media #{$lg-layout} {
                width: 162px;
            }
            @media #{$md-layout} {
                width: 162px;
            }
            @media #{$xs-layout} {
                width: 162px;
            }
        }
    }
    .kids-pattern-4 {
        position: absolute;
        bottom: 15%;
        right: 53px;
        animation-iteration-count: infinite;
        animation-name: bounce-img;
        animation-duration: 2.5s;
        @media #{$xs-layout} {
            right: 10px;
        }
        img {
            width: 157px;
            @media #{$lg-layout} {
                width: 80px;
            }
            @media #{$md-layout} {
                width: 80px;
            }
            @media #{$xs-layout} {
                width: 80px;
            }
        }
    }
    .kids-pattern-5 {
        position: absolute;
        bottom: 65px;
        left: 10%;
        animation-iteration-count: infinite;
        animation-name: bounce-img;
        animation-duration: 2.5s;
        @media #{$xs-layout} {
            left: 2%;
        }
        img {
            width: 157px;
            @media #{$lg-layout} {
                width: 80px;
            }
            @media #{$md-layout} {
                width: 80px;
            }
            @media #{$xs-layout} {
                width: 80px;
            }
        }
    }
}

.kids-deal-img {
    margin-left: -50px;
    @media #{$xl-layout} {
        margin-left: 0px;
    }
    @media #{$lg-layout} {
        margin-left: 0px;
    }
    @media #{$md-layout} {
        margin-left: 0px;
    }
    @media #{$xs-layout} {
        margin-left: 0px;
    }
    a {
        img {
            width: 100%;
        }
    }
}

.kids-deal-content {
    margin: 0 -52px 0 66px;
    position: relative;
    z-index: 9;
    @media #{$xl-layout} {
        margin: 0 0px 0 0px;
    }
    @media #{$lg-layout} {
        margin: 0 0px 0 0px;
    }
    @media #{$md-layout} {
        margin: 0 0px 0 0px;
    }
    @media #{$xs-layout} {
        margin: 15px 0px 0 0px;
    }
    h2 {
        color: #1b1b1b;
        font-size: 46px;
        font-weight: 600;
        margin: 0;
        @media #{$xs-layout} {
            font-size: 38px;
        }
    }
    > p {
        color: #1b1b1b;
        font-size: 24px;
        font-weight: 300;
        margin: 27px auto 55px;
        width: 81%;
        line-height: 30px;
        @media #{$lg-layout} {
            width: 95%;
            font-size: 20px;
            margin: 17px auto 25px;
        }
        @media #{$md-layout} {
            width: 95%;
            font-size: 16px;
            margin: 10px auto 25px;
        }
        @media #{$xs-layout} {
            width: 95%;
            font-size: 17px;
            margin: 3px auto 25px;
        }
    }
}

.timer-style-2 {
    margin: 0 0 56px;
    @media #{$lg-layout} {
        margin: 0 0 26px;
    }
    @media #{$md-layout} {
        margin: 0 0 15px;
    }
    @media #{$xs-layout} {
        margin: 0 0 15px;
    }
    > div {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        > span {
            font-size: 24px;
            color: #1b1b1b;
            font-style: italic;
            width: 120px;
            height: 120px;
            background-color: #ffedc3;
            text-align: center;
            border-radius: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            margin: 0 22px;
            @media #{$xl-layout} {
                margin: 0 10px;
            }
            @media #{$lg-layout} {
                margin: 0 12px;
                width: 90px;
                height: 90px;
            }
            @media #{$md-layout} {
                margin: 0 10px 12px;
                width: 90px;
                height: 90px;
            }
            @media #{$xs-layout} {
                margin: 0 10px 12px;
                width: 90px;
                height: 90px;
            }
            p {
                margin: 0;
                font-size: 16px;
                color: #1b1b1b;
                line-height: 1;
            }
        }
    }
}

.product-tab-list-3 {
	display: flex;
	justify-content: center;
    a {
        display: inline-block;
        line-height: 1;
        margin: 0 36px;
        color: $theme-color-yellow;
        font-size: 32px;
        padding: 0 0 21px;
        position: relative;
        text-align: center;
        font-family: $fredokaone;
        @media #{$lg-layout} {
            margin: 0 26px;
            font-size: 27px;
            padding: 0 0 15px;
        }
        @media #{$md-layout} {
            margin: 0 17px;
            font-size: 22px;
            padding: 0 0 15px;
        }
        @media #{$xs-layout} {
            margin: 0 10px 10px;
            font-size: 22px;
            padding: 0 0 11px;
        }
        &:before {
            position: absolute;
            content: "";
            left: 0;
            right: 0;
            margin: 0 auto;
            width: 100%;
            height: 5px;
            bottom: 0;
            border-radius: 5px;
            background-color: transparent;
        }
        &.active {
            &:before {
                background-color: #c7c7c7;
            }
        }
    }
    @media #{$xs-layout} {
        &.mb-50 {
            margin-bottom: 20px;
        }
    }
}

.product-cake-wrap {
    .product-cake-img {
        overflow: hidden;
        a {
            display: block;
            img {
                width: 100%;
                transform: scale(1) rotate(0deg);
                transition: all .5s ease 0s;
            }
        }
    }
    .product-cake-content {
        h2 {
            font-size: 66px;
            color: #1b1b1b;
            margin: 0;
            font-family: $signpainterhousescript;
            @media #{$md-layout} {
                font-size: 52px;
            }
            @media #{$xs-layout} {
                font-size: 45px;
                margin: 20px 0 0;
            }
            @media #{$sm-layout} {
                font-size: 40px;
                margin: 0px 0 0;
            }
            a {
                color: #1b1b1b;
                &:hover {
                    color: $theme-color-pink;
                }
            }
        }
        p {
            color: #8b8b8b;
            font-size: 18px;
            line-height: 36px; 
            margin: 17px 0 29px;
            width: 88%;
            @media #{$md-layout} {
                font-size: 15px;
                line-height: 30px; 
                width: 100%;
            }
            @media #{$xs-layout} {
                font-size: 15px;
                line-height: 28px;
                margin: 10px 0 20px;
                width: 100%;
            }
        }
    }
    &:hover {
        .product-cake-img {
            a {
                img {
                    transform: scale(1.02) rotate(5deg);
                }
            }
        }
    }
}

.cake-product-mrg1 {
    margin-top: -25px;
    @media #{$xx-layout} {
        margin-top: 0px;
        &.pb-85 {
            padding-bottom: 30px;
        }
    }
    @media #{$xl-layout} {
        margin-top: 0px;
        &.pb-85 {
            padding-bottom: 30px;
        }
    }
    @media #{$lg-layout} {
        margin-top: 0px;
        &.pb-85 {
            padding-bottom: 30px;
        }
    }
    @media #{$md-layout} {
        margin-top: 0px;
        &.pb-85 {
            padding-bottom: 30px;
        }
    }
    @media #{$xs-layout} {
        &.pb-85 {
            padding-bottom: 10px;
        }
    }
}

.cake-product-mrg2 {
	margin-top: -55px;
    @media #{$xx-layout} {
        margin-top: 0px;
        &.pb-50 {
            padding-bottom: 30px;
        }
    }
    @media #{$xl-layout} {
        margin-top: 0px;
        &.pb-50 {
            padding-bottom: 30px;
        }
    }
    @media #{$lg-layout} {
        margin-top: 0px;
        &.pb-50 {
            padding-bottom: 0px;
        }
    }
    @media #{$md-layout} {
        margin-top: 0px;
        &.pb-50 {
            padding-bottom: 0px;
        }
    }
    @media #{$xs-layout} {
        margin-top: 30px;
        &.pb-50 {
            padding-bottom: 0px;
        }
    }
}

.organic-product-ptb-1 {
	padding: 401px 0 300px;
    @media #{$xl-layout} {
        padding: 301px 0 200px; 
    }
    @media #{$lg-layout} {
        padding: 301px 0 200px; 
    }
    @media #{$md-layout} {
        padding: 280px 0 250px;
        padding: 150px 0 250px;
        background-position: 69%;
    }
    @media #{$xs-layout} {
        padding: 100px 0 100px;
        background-position: 86%;
    }
}
.organic-product-ptb-2 {
	padding: 189px 0 359px;
    @media #{$xl-layout} {
        padding: 189px 0 259px;
    }
    @media #{$lg-layout} {
        padding: 189px 0 259px;
    }
    @media #{$md-layout} {
        padding: 150px 0 280px;
    }
    @media #{$xs-layout} {
        padding: 80px 0 150px;
    }
}





