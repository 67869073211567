/*-------- 2. Header style ---------*/

.header-small-device {
    display: none;
    padding: 25px 0;
    @media #{$md-layout} {
        display: block;
    }
    @media #{$xs-layout} {
        display: block;
    }
}

.header-large-device {
    display: block;
    @media #{$md-layout} {
        display: none;
    }
    @media #{$xs-layout} {
        display: none;
    }
}

.logo {
    a {
        img {
            max-width: 100%;
        }
    }
}

@media #{$lg-layout} {
    .logo-res-lg {
        a {
            img {
                width: 100%;
            }
        }
    }
}
@media #{$xs-layout} {
    .mobile-logo-width {
        a {
            img {
                width: 110px;
            }
        }
    }
}
@media #{$sm-layout} {
    .mobile-logo-width {
        a {
            img {
                width: 130px;
            }
        }
    }
}

.header-ptb {
    padding: 50px 0;
}

.header-ptb-2 {
	padding: 63px 0 63px;
}

.search-wrap {
    form {
        position: relative;
        input {
            color: #393939;
            height: 30px;
            padding: 0 40px 0 0;
            background-color: transparent;
            border: none;
            font-size: 16px;
            &::-moz-input-placeholder {
                color: #393939;
                opacity: 1;
            }
            &::-webkit-input-placeholder {
                color: #393939;
                opacity: 1;
            }
        }
        button {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            border: none;
            background-color: transparent;
            padding: 0;
            transition: all .3s ease 0s;
            i {
                font-size: 30px;
                color: #393939;
                @media #{$lg-layout} {
                    font-size: 24px;
                }
                &:hover {
                    color: #666;
                }
            }
        }
    }
    &.search-width-1 {
        width: 220px;
        @media #{$lg-layout} {
            width: 200px;
        }
    }
    &.search-width-2 {
        width: 185px;
    }
    &.search-wrap-2-mrg {
        margin: 0 auto;
    }
    &.search-wrap-2 {
       form {
            input {
                height: 53px;
                font-size: 14px;
                border-bottom: 1px solid #d8d8d8;
            }
            button {
                i {
                    font-size: 20px;
                }
            }
        } 
    }
}

.header-right-wrap {
    &.header-right-flex { 
        display: flex;
        justify-content: flex-end;
        align-items: center;
        line-height: 1;
    }
    &.header-center-flex { 
        display: flex;
        justify-content: center;
        line-height: 1;
    }
    &.header-right-wrap-mrg { 
        margin: 64px 0 49px;
        @media #{$xx-layout} {
            margin: 44px 0 29px;
        }
        @media #{$xl-layout} {
            margin: 44px 0 29px;
        }
        @media #{$lg-layout} {
            margin: 34px 0 19px;
        }
    }
    .same-style {
        margin-left: 64px;
        @media #{$lg-layout} {
            margin-left: 30px;
        }
        @media #{$md-layout} {
            margin-left: 40px;
        }
        @media #{$xs-layout} {
            margin-left: 24px;
        }
        &:first-child {
            margin-left: 0;
        }
        a {
            font-size: 30px;
            @media #{$lg-layout} {
                font-size: 24px;
            }
            @media #{$xs-layout} {
                font-size: 24px;
            }
            &:hover {
                color: #666;
            }
        }
        &.same-style-mrg-dec {
            margin-left: 38px;
            @media #{$xl-layout} {
                margin-left: 20px;
            }
            @media #{$lg-layout} {
                margin-left: 20px;
            }
            &:first-child {
                margin-left: 0;
            }
        }
        &.same-style-mrg-dec-2 {
            margin-left: 32px;
            @media #{$xl-layout} {
                margin-left: 20px;
            }
            @media #{$lg-layout} {
                margin-left: 20px;
            }
            &:first-child {
                margin-left: 0;
            }
        }
        &.same-style-font-dec {
            a {
                font-size: 20px;
            }
        }
    }
    @media #{$lg-layout} {
        &.hr-mrg-handmade {
            .same-style-mrg-dec {
                margin-left: 13px;
                &:first-child {
                    margin-left: 0;
                }
            }
        }
    }
}

.free-delivery {
    h4 {
        font-weight: 500;
        font-size: 14px;
        color: #1d1d1d;
        margin: 0;
        span {
            color: $theme-color-yellow;
            &.free-delivery-black {
                color: #1d1d1d;
            }
        }
    }
}

.same-style-2 {
    margin-left: 27px;
    ul {
        > li {
            position: relative;
            > a {
                font-weight: 500;
                color: #1d1d1d;
                font-size: 12px;
                display: inline-block;
                line-height: 30px;
                i {
                    font-size: 12px;
                    margin-left: 6px;
                    @media #{$lg-layout} {
                        margin-left: 0px;
                    }
                }
            }
            &:hover > a {
                color: $theme-color-yellow;
            }
            ul {
                position: absolute;
                left: 0;
                top: 100%;
                width: 100px;
                box-shadow: 0px 1px 24px 0px rgba(0, 0, 0, 0.09);
                background-color: #262626;
                padding: 27px 0px;
                transform: rotateX(90deg);
                transform-origin: center top 0;
                transition: all 0.5s ease 0s;
                visibility: hidden;
                z-index: 9;
                @media #{$xx-layout} {
                    right: 0;
                    left: auto;
                }
                @media #{$xl-layout} {
                    right: 0;
                    left: auto;
                    width: 90px;
                }
                @media #{$lg-layout} {
                    right: 0;
                    left: auto;
                    width: 90px;
                }
                > li {
                    display: block;
                    line-height: 1;
                    > a {
                        display: block;
                        color: #ddd;
                        padding: 0 25px 20px;
                        line-height: 1;
                        @media #{$xx-layout} {
                            text-align: center;
                        }
                        @media #{$xl-layout} {
                            padding: 0 15px 20px;
                            text-align: center;
                        }
                        @media #{$lg-layout} {
                            padding: 0 15px 20px;
                            text-align: center;
                        }
                        &:hover {
                            color: $theme-color-yellow;
                        }
                    }
                    &:last-child a {
                        padding: 0 25px 0px;
                        @media #{$xx-layout} {
                            text-align: center;
                        }
                        @media #{$xl-layout} {
                            padding: 0 15px 0px;
                            text-align: center;
                        }
                        @media #{$lg-layout} {
                            padding: 0 15px 0px;
                            text-align: center;
                        }
                    }
                }
            }
            &:hover ul {
                transform: rotateX(0deg);
                visibility: visible; 
            }
        }
    }
}

.header-cll {
    span {
        font-size: 24px;
        font-weight: 600;
        display: flex;
        align-items: center;
        @media #{$lg-layout} {
            font-size: 20px;
        }
        i {
            font-size: 42px;
            color: $theme-color-yellow;
            margin-right: 18px;
            &.black {
                color: #393939;
            }
        }
    }
}

.hb-negative-mrg {
    margin-bottom: -80px;
    position: relative;
    z-index: 99;
}

.main-menu {
    > nav {
        > ul {
            > li {
                display: inline-block;
                position: relative;
                > a {
                    font-weight: 600;
                    letter-spacing: 1.82px;
                    display: inline-block;
                    font-size: 11px;
                    position: relative;
                    &::after {
                        position: absolute;
                        left: 0;
                        right: 0;
                        margin: auto;
                        top: 37%;
                        bottom: 0;
                        width: 20%;
                        height: 2px;
                        content: "";
                        z-index: 1;
                        opacity: 0;
                        transition: all 0.3s;
                    }
                    &.active::after {
                        width: calc(100%);
                        opacity: 1;
                    }
                }
                > ul {
                    box-shadow: 0px 1px 24px 0px rgba(0, 0, 0, 0.09);
                    background-color: #262626;
                    position: absolute;
                    top: 100%;
                    left: 0;
                    transition: margin .1s linear .3s,visibility .1s linear .3s,opacity .1s linear .3s;
                    opacity: 0;
                    visibility: hidden;
                    margin-top: 20px;
                    z-index: 9999;
                    &.sub-menu-width {
                        width: 220px;
                        padding: 39px 0 43px;
                        li {
                            display: block;
                            padding: 0 15px 14px 30px;
                            position: relative;
                            text-align: left;
                            &:last-child {
                                padding-bottom: 0;
                            }
                            a {
                                display: block;
                                color: #D0D0D0;
                                font-size: 12px;
                                font-weight: 300;
                                i {
                                    float: right;
                                    font-size: 12px;
                                    color: #999;
                                    font-weight: 600;
                                    position: relative;
                                    top: 6px;
                                }
                                &:hover {
                                    color: $theme-color-yellow;
                                    text-decoration: underline;
                                }
                            }
                            ul.lavel-menu {
                                padding: 36px 0px 40px;
                                width: 220px;
                                transition: all 250ms ease-out;
                                box-shadow: 0px 1px 24px 0px rgba(0, 0, 0, 0.09);
                                background-color: #262626;
                                position: absolute;
                                top: -17px;
                                left: 100%;
                                margin: 0;
                                visibility: hidden;
                                opacity: 0;
                                @media #{$lg-layout} {
                                    width: 180px;
                                }
                            }
                            &:hover > ul.lavel-menu {
                                visibility: visible;
                                opacity: 1;
                            }
                        }
                    }
                    &.mega-menu-width1 {
                        width: 450px;
                    }
                    &.mega-menu-width3 {
                        width: 625px;
                        > li {
                            &.mega-menu-sub-width33 {
                                width: 33.333%;
                            }
                        }
                    }
                    &.mega-menu-style-1 {
                        padding: 40px 10px 33px 40px;
                        > li {
                            text-align: left;
                            &.mega-menu-sub-width50 {
                                width: 50%;
                            }
                            padding-right: 30px;
                            float: left;
                            display: block;
                            a {
                                &.menu-title {
                                    color: #fff;
                                    font-size: 14px;
                                    padding: 0px 0px 12px 0px;
                                    position: relative;
                                    &:before {
                                        position: absolute;
                                        content: "";
                                        width: 25px;
                                        height: 2px;
                                        background: rgba(255, 255, 255, 0.2);
                                        bottom: -2px;
                                    }
                                }
                            }
                            > ul {
                                margin: 28px 0 0;
                                li {
                                    display: block;
                                    a {
                                        color: #D0D0D0;
                                        font-size: 12px;
                                        display: block;
                                        padding: 8px 0px;
                                        &:hover {
                                            color: $theme-color-yellow;
                                            text-decoration: underline;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    &.mega-menu-width2 {
                        width: 1170px;
                        @media #{$lg-layout} {
                            width: 950px;
                        }
                    }
                    &.menu-negative-mrg1 {
                        left: -186px;
                        @media #{$xx-layout} {
                            left: -188px;
                        }
                        @media #{$xl-layout} {
                            left: -188px;
                        }
                        @media #{$lg-layout} {
                            left: -148px;
                        }
                    }
                    &.menu-negative-mrg2 {
                        left: -250px;
                    }
                    &.menu-negative-mrg3 {
                        left: -504px;
                        @media #{$xx-layout} {
                            left: -481px;
                        }
                        @media #{$xl-layout} {
                            left: -482px;
                        }
                        @media #{$lg-layout} {
                            left: -424px;
                        }
                    }
                    &.menu-negative-mrg4 {
                        left: -480px;
                        @media #{$xx-layout} {
                            left: -450px;
                        }
                        @media #{$xl-layout} {
                            left: -472px;
                        }
                        @media #{$lg-layout} {
                            left: -366px;
                        }
                    }
                    &.menu-negative-mrg5 {
                        left: -395px;
                        @media #{$xx-layout} {
                            left: -395px;
                        }
                        @media #{$xl-layout} {
                            left: -440px;
                        }
                        @media #{$lg-layout} {
                            left: -349px;
                        }
                    }
                    &.mega-menu-style-2 {
                        padding: 52px 40px 45px 10px;
                        @media #{$lg-layout} {
                            padding: 52px 30px 45px 0px;
                        }
                        > li {
                            text-align: left;
                            &.mega-menu-sub-width20 {
                                width: 20%;
                                @media #{$lg-layout} {
                                    width: 19%;
                                }
                            }
                            &.mega-menu-sub-width22 {
                                width: 22.5%;
                                @media #{$lg-layout} {
                                    width: 27.5%;
                                }
                            }
                            &.mega-menu-sub-width37 {
                                width: 37.5%;
                                @media #{$lg-layout} {
                                    width: 34.5%;
                                }
                            }
                            float: left;
                            display: block;
                            a {
                                &.menu-title {
                                    color: #fff;
                                    font-size: 14px;
                                    padding: 0px 30px 12px 30px;
                                    position: relative;
                                    &:before {
                                        position: absolute;
                                        content: "";
                                        width: 25px;
                                        height: 2px;
                                        background: rgba(255, 255, 255, 0.2);
                                        bottom: -2px;
                                    }
                                }
                            }
                            > ul {
                                margin: 28px 0 0;
                                li {
                                    display: block;
                                    a {
                                        color: #D0D0D0;
                                        font-size: 12px;
                                        display: block;
                                        padding: 8px 30px;
                                        &:hover {
                                            color: $theme-color-yellow;
                                            text-decoration: underline;
                                        }
                                    }
                                }
                            }
                            .banner-menu-content-wrap {
                                position: relative;
                                &.default-overlay::before {
                                    background-color: #000;
                                    opacity: .2;
                                    z-index: 1;
                                    pointer-events: none;
                                }
                                a {
                                    img {
                                        width: 100%;
                                    }
                                }
                                .banner-menu-content {
                                    position: absolute;
                                    left: 41px;
                                    bottom: 35px;
                                    z-index: 9;
                                    h2 {
                                        color: #fff;
                                        font-size: 32px;
                                        font-weight: bold;
                                        margin: 0;
                                        letter-spacing: 0px;
                                        line-height: 45px;
                                        transition: all .3s ease 0s;
                                        @media #{$lg-layout} {
                                            font-size: 35px;
                                            line-height: 50px;
                                        }
                                    }
                                }
                                &:hover {
                                    &.default-overlay::before {
                                        background-color: #000;
                                        opacity: .4;
                                        z-index: 1;
                                        pointer-events: none;
                                    }
                                    .banner-menu-content {
                                        h2 {
                                            letter-spacing: 1px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                &:hover > ul {
                    opacity: 1;
                    visibility: visible;
                    margin-top: 0px;
                }
                &:hover > a::after {
                    width: calc(100%);
                    opacity: 1;
                }
            }
        }
    }
    &.menu-text-white {
        > nav {
            > ul {
                > li {
                    > a {
                        color: #fff;
                        &::after {
                            background-color: #fff;
                        }
                    }
                }
            }
        }
    }
    &.menu-text-black {
        > nav {
            > ul {
                > li {
                    > a {
                        color: #393939;
                        &::after {
                            background-color: #393939;
                        }
                    }
                }
            }
        }
    }
    &.menu-lh-1 {
        > nav {
            > ul {
                > li {
                    > a {
                        line-height: 80px;
                    }
                }
            }
        }
    }
    &.menu-lh-2 {
        > nav {
            > ul {
                > li {
                    > a {
                        line-height: 60px;
                    }
                }
            }
        }
    }
    &.menu-lh-3 {
        > nav {
            > ul {
                > li {
                    > a {
                        line-height: 167px;
                        @media #{$lg-layout} {
                            line-height: 120px;
                        }
                        &::after {
                            top: 20%; 
                        }
                    }
                }
            }
        }
    }
    &.menu-lh-4 {
        > nav {
            > ul {
                > li {
                    > a {
                        line-height: 148px;
                    }
                }
            }
        }
    }
    &.kid-main-menu {
        > nav {
            > ul {
                > li {
                    > a {
                        font-size: 20px;
                        font-family: $fredokaone;
                        color: #393939;
                        font-weight: 400;
                        @media #{$xl-layout} {
                            font-size: 16px;
                        }
                        @media #{$lg-layout} {
                            font-size: 14px;
                        }
                        &:hover {
                            color: $theme-color-yellow;
                        }
                    }
                }
            }
        }
    }
    &.menu-border-none {
        > nav {
            > ul {
                > li {
                    > a {
                        &::after {
                            display: none;
                        }
                    }
                }
            }
        }
    }
    &.main-menu-padding-1 {
        > nav {
            > ul {
                > li {
                    padding: 0 24px;
                    @media #{$xx-layout} {
                        padding: 0 18px;
                    }
                    @media #{$xl-layout} {
                        padding: 0 18px;
                    }
                    @media #{$lg-layout} {
                        padding: 0 16px;
                    }
                }
            }
        }
    }
    &.main-menu-padding-2 {
        > nav {
            > ul {
                > li {
                    padding: 0 48px 0 0;
                    @media #{$lg-layout} {
                        padding: 0 28px 0 0;
                    }
                }
            }
        }
    }
    &.main-menu-padding-3 {
        > nav {
            > ul {
                > li {
                    padding: 0 40px;
                    @media #{$xx-layout} {
                        padding: 0 30px;
                    }
                    @media #{$xl-layout} {
                        padding: 0 20px;
                    }
                    @media #{$lg-layout} {
                        padding: 0 15px;
                    }
                }
            }
        }
    }
    &.main-menu-padding1 {
        padding-left: 25px;
    }
}

.category-menu-wrap {
    position: relative;
    @media #{$md-layout} {
        margin-top: 25px;
    }
    @media #{$xs-layout} {
        margin-top: 25px;
    }
    h3 {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        color: #fff;
        margin: 0;
        line-height: 80px;
        &.font-width-inc {
            font-weight: 600;
        }
        @media #{$md-layout} {
            line-height: 60px;
        }
        @media #{$xs-layout} {
            line-height: 50px;
            font-size: 17px;
        }
        a {
            color: #fff;
            i {
                font-size: 30px;
                position: relative;
                top: 7px;
                margin-right: 13px;
            }
            &:hover {
                color: #ddd;
            }
        }
    }
}

.clickalbe-sidebar-wrapper-style-1 {
	position: fixed;
	top: 0;
	width: 400px;
	min-height: 100vh;
	bottom: 0;
	z-index: 111;
	right: 0;
	visibility: hidden;
	opacity: 0;
	transition: all 400ms ease-out;
	transform: translate(200px,0);
    background-color: #1D1D1D;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.09);
    z-index: 9999;
    @media #{$md-layout} {
        width: 330px;
    }
    @media #{$xs-layout} {
        width: 290px;
    }
    &.clickalbe-sidebar-active-left {
        transform: translateX(-410px);
        left: 0;
    }
    &.sidebar-visible {
        visibility: visible;
        opacity: 1;
        transform: translate(0,0);
    }
    &.mobilemenu-visible {
        visibility: visible;
        opacity: 1;
        transform: translate(0,0);
    }
    & .clickalbe-sidebar-wrap {
        padding: 170px 70px 20px;
        height: 100%;
        @media #{$md-layout} {
            padding: 100px 30px 20px;
        }
        @media #{$xs-layout} {
            padding: 70px 20px 20px;
        }
        &.clickalbe-sidebar-padding-dec {
            padding: 150px 50px 20px;
            .sidebar-close {
                left: 50px;
            }
        }
        .sidebar-close {
            position: absolute;
            top: 80px;
            left: 70px;
            font-size: 26px;
            line-height: 30px;
            transition: all 250ms ease-out;
            color: #BDBDBD;
            @media #{$md-layout} {
                left: 30px;
                top: 30px;
            }
            @media #{$xs-layout} {
                top: 20px;
                left: 20px;
                font-size: 23px;
            }
            &:hover {
                transform: rotate(90deg);
                color: $theme-color-yellow;
            }
        }
        .sidebar-content-100-percent {
            height: 100%;
            overflow-y: auto;
            padding-right: 10px;
        }
        .clickable-mainmenu-style1 {
            > nav {
                > ul {
                    > li {
                        display: block;
                        padding-bottom: 40px;
                        line-height: 1;
                        @media #{$md-layout} {
                            padding-bottom: 22px;
                        }
                        @media #{$xs-layout} {
                            padding-bottom: 22px;
                        }
                        &:last-child {
                            padding-bottom: 0;
                        }
                        > a {
                            font-size: 18px;
                            display: block;
                            text-transform: capitalize;
                            color: #BDBDBD;
                            @media #{$md-layout} {
                                font-size: 16px;
                            }
                            @media #{$xs-layout} {
                                font-size: 16px;
                            }
                            i {
                                float: right;
                                top: 2px;
                                position: relative;
                                color: #BDBDBD;
                                transition: all .3s ease 0s;
                                @media #{$xs-layout} {
                                    font-size: 14px;
                                }
                            }
                        }
                        &:hover > a {
                            color: $theme-color-yellow;
                        }
                        &:hover > a i {
                            color: $theme-color-yellow;
                        }
                        ul {
                            padding: 38px 0 0px 25px;
                            li {
                                padding-bottom: 22px;
                                @media #{$xs-layout} {
                                    padding-bottom: 18px;
                                }
                                &:last-child {
                                    padding-bottom: 0;
                                }
                                a {
                                    color: #BDBDBD;
                                    font-size: 14px;
                                    display: block;
                                    
                                    i {
                                        float: right;
                                        top: -1px;
                                        position: relative;
                                    }
                                }
                                &:hover > a {
                                    color: $theme-color-yellow;
                                }
                                &:hover > a i {
                                    color: $theme-color-yellow;
                                }
                            }
                        }
                    }
                }
            }
        }
        .header-aside-content {
            .header-aside-menu {
                margin: 0 0 25px;
                nav {
                    ul {
                        li {
                            display: block;
                            a {
                                font-size: 15px;
                                color:#BDBDBD;
                                display: block;
                                padding: 0 0 15px;
                                @media #{$xs-layout} {
                                    font-size: 15px;
                                    padding: 0 0 16px;
                                }
                                &:hover {
                                    color: $theme-color-yellow;
                                }
                            }
                            &:last-child a {
                                padding: 0 0 0px;
                            }
                        }
                    }
                }
            }
            .header-aside-payment {
                img {
                    max-width: 100%;
                }
            }
            > p {
                line-height: 1.714286;
                color: #BDBDBD;
                margin: 29px 0 0;
            }
            .aside-contact-info {
                margin: 30px 0 30px;
                ul {
                    li {
                        color: #BDBDBD;
                        display: flex;
                        margin: 0 0 23px;
                        &:last-child {
                            margin: 0 0 0px;
                        }
                        i {
                            font-size: 22px;
                            margin: 1px 16px 0px 0;
                        }
                    }
                }
            }
        }
    }
}

.main-wrapper {
    .body-overlay {
        background: rgba(35, 35, 36, 0.7) none repeat scroll 0 0;
        height: 100%;
        left: 0;
        opacity: 0;
        position: fixed;
        top: 0;
        transition: all 0.5s ease-in-out 0s;
        visibility: hidden;
        width: 100%;
        z-index: 9999;
    }
    &.overlay-active {
        .body-overlay {
            opacity: 1;
            visibility: visible;
        }
    }
} 

.sidebar-cart-active {
	position: fixed;
	top: 0;
	width: 420px;
	padding: 104px 50px 50px;
	min-height: 100vh;
	bottom: 0;
	z-index: 9999;
	right: 0;
	visibility: hidden;
	opacity: 0;
	transition: all 250ms ease-in;
	transform: translate(200px,0);
	box-shadow: 0 0 87px 0 rgba(0,0,0,.09);
    background-color: #fff;
    &.inside {
        visibility: visible;
        opacity: 1;
        transform: translate(0,0);
    }
    @media #{$md-layout} {
        width: 350px;
        padding: 104px 30px 50px;
    }
    @media #{$xs-layout} {
        padding: 50px 15px 50px;
        width: 280px;
    }
    .sidebar-cart-all {
        .cart-close {
            position: absolute;
            top: 48px;
            left: 55px;
            font-size: 30px;
            line-height: 30px;
            transition: all 250ms ease-out;
            @media #{$md-layout} {
                left: 30px;
                font-size: 25px;
            }
            @media #{$xs-layout} {
                top: 20px;
                left: 23px;
                font-size: 23px;
            }
            &:hover {
                transform: rotate(90deg);
                color: $theme-color-yellow;
            }
        }
        .cart-content {
            > h3 {
                font-size: 18px;
                font-weight: 500;
                margin: 0 0 30px;
                color: #393939;
            }
            ul {
                margin: 0 -30px 0 0;
                padding: 0 30px 0 0;
                max-height: 310px;
                max-height: calc(100vh - 430px);
                overflow: auto;
                @media #{$md-layout} {
                    max-height: calc(100vh - 365px);
                }
                @media #{$xs-layout} {
                    max-height: calc(100vh - 360px);
                }
                li {
                    display: flex;
                    margin-bottom: 25px;
                    .cart-img {
                        flex: 0 0 75px;
                        a {
                            img {
                                width: 100%;
                                border: 1px solid rgba(0, 0, 0, 0.1);
                            }
                        }
                    }
                    .cart-title {
                        margin-left: 20px;
                        margin-top: 2px;
                        h4 {
                            font-size: 14px;
                            margin: 0 0 8px;
                        }
                        span {
                            font-size: 14px;
                            color: #262626;
                        }
                    }
                    .cart-delete {
                        display: flex;
                        flex-grow: 100;
                        justify-content: flex-end;
                        a {
                            display: block;
                            font-weight: 400;
                            font-size: 14px;
                            height: 20px;
                            line-height: 17px;
                            overflow: hidden;
                            width: 20px;
                            text-align: right;
                        }
                    }
                }
            }
            .cart-total {
                display: block;
                width: 100%;
                margin: 18px 0 43px;
                h4 {
                    font-size: 14px;
                    color: #535353;
                    margin: 0;
                    span {
                        font-size: 18px;
                        color: #2F2F2F;
                    }
                }
            }
            .cart-checkout-btn {
                display: block;
                width: 100%;
                a {
                    width: 100%;
                    display: block;
                    margin: 10px 0 0;
                    text-align: center;
                    line-height: 20px;
                    padding: 19px 20px 20px;
                    background-color: #2F2F2F;
                    color: #fff;
                    text-transform: capitalize;
                    font-size: 14px;
                    &:hover {
                        background-color: $theme-color-yellow;
                    }
                    &:first-child {
                        margin-top: 0;
                    }
                }
            }
        }
    }
}

.social-icon-style-2 {
    a {
        display: inline-block;
        font-size: 12px;
        width: 31px;
        height: 31px;
        text-align: center;
        border-radius: 100%;
        color: #fff;
        margin-right: 5px;
        border: 1px solid transparent;
        &:last-child {
            margin-right: 0px;
        }
        i {
            line-height: 30px;
            font-size: 12px;
        }
        &.facebook {
            background-color: #3b5998;
            &:hover {
                opacity: .9;
                color: #fff;
            }
        }
        &.twitter {
            background-color: #1da1f2;
            &:hover {
                opacity: .9;
                color: #fff;
            }
        }
        &.google-plus {
            background-color: #dd4b39;
            &:hover {
                opacity: .9;
                color: #fff;
            }
        }
        &.behance {
            background-color: #1769ff;
            &:hover {
                opacity: .9;
                color: #fff;
            }
        }
    }
}

.mobile-menu-content-area {
    .mobile-search {
        border-bottom: 1px solid rgba(255, 255, 255, 0.09);
        margin-bottom: 27px;
        padding-bottom: 40px;
        form {
            position: relative;
            input {
                background-color: rgba(255, 255, 255, 0.05);
                border: none;
                border-radius: 0px;
                height: 50px;
                padding: 0 60px 0 15px;
                width: 100%;
                font-size: 14px;
                color: #BDBDBD;
            }
            button {
                background-color: transparent;
                border-color: rgba(255, 255, 255, 0.09);
                border-image: none;
                border-radius: 5px 0 0 5px;
                border-style: none none none solid;
                border-width: medium medium medium 1px;
                color: #BDBDBD;
                font-size: 15px;
                height: 100%;
                padding: 0 15px 0 14px;
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
                transition: all 0.3s ease 0s;
                @media #{$xs-layout} {
                    font-size: 16px;
                }
                &:hover{
                    color: $theme-color-yellow;
                }
                i {
                    margin-top: 5px;
                    display: inline-block;
                }
            }
        }
    }
    .mobile-curr-lang-wrap {
        border-bottom: 1px solid rgba(255, 255, 255, 0.09);
        margin-bottom: 31px;
        padding-bottom: 37px;
        border-top: 1px solid rgba(255, 255, 255, 0.09);
        padding-top: 25px;
        margin-top: 33px;
        .single-mobile-curr-lang {
            position: relative;
            margin-bottom: 15px;
            &:last-child {
                margin-bottom: 0;
            }
            a {
                font-size: 15px;
                display: block;
                color: #BDBDBD;
                i {
                    float: right;
                    font-size: 14px;
                    position: relative;
                    top: 5px;
                }
            }
            .lang-curr-dropdown {
                margin-top: 5px;
                display: none;
                background-color: rgba(255, 255, 255, 0.05);
                box-shadow: 0 0 20px 0.4px rgba(0, 0, 0, 0.1);
                padding: 22px 30px 26px;
                width: 100%;
                z-index: 11;
                ul {
                    li {
                        padding-bottom: 10px;
                        &:last-child {
                            padding-bottom: 0px;
                        }
                        a {
                            font-size: 14px;
                            font-weight: 400;
                            color: #BDBDBD;
                            &:hover {
                                color: $theme-color-yellow;
                            }
                        }
                    }
                }
            }
            &:hover > a {
                color: $theme-color-yellow;
            }
            @media #{$xs-layout} {
                margin-bottom: 5px;
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}

.header-sidebar-wrap-all {
	padding: 120px 55px 50px;
	width: 375px;
	position: fixed;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 9;
    @media #{$xx-layout} {
        padding: 80px 30px 50px;
        width: 275px;
    }
    @media #{$xl-layout} {
        padding: 80px 30px 50px;
        width: 270px;
    }
    @media #{$lg-layout} {
        padding: 50px 20px 50px;
        width: 250px;
    }
    @media #{$md-layout} {
        display: none;
    }
    @media #{$xs-layout} {
        display: none;
    }
    .header-sidebar-wrap {
        height: 100%;
        width: 100%;
        overflow-y: auto;
    }
}

.clickable-mainmenu-style-2 {
    padding: 95px 0 95px;
    @media #{$xx-layout} {
        padding: 55px 0 55px;
    }
    @media #{$xl-layout} {
        padding: 55px 0 55px;
    }
    @media #{$lg-layout} {
        padding: 45px 0 45px;
    }
    > nav {
        ul {
            > li {
                padding-bottom: 48px;
                display: block;
                @media #{$xx-layout} {
                    padding-bottom: 30px;
                }
                @media #{$xl-layout} {
                    padding-bottom: 20px;
                }
                @media #{$lg-layout} {
                    padding-bottom: 20px;
                }
                &:last-child {
                    padding-bottom: 0;
                }
                a {
                    display: inline-block;
                    font-size: 24px;
                    color: #1b1b1b;
                    font-weight: bold;
                    font-family: $playfairdisplay;
                    @media #{$lg-layout} {
                        font-size: 20px;
                    }
                    i {
                        margin-left: 5px;
                        font-size: 18px;
                    }
                }
                &:hover > a {
                    color: $theme-color-green;
                }
                ul {
                    padding: 18px 0 0px 0px;
                    li {
                        padding-bottom: 6px;
                        &:last-child {
                            padding-bottom: 0;
                        }
                        a {
                            font-size: 16px;
                            color: #444;
                            i {
                                position: relative;
                                top: 1px;
                                font-size: 14px;
                            }
                        }
                        ul {
                            padding: 18px 0 0px 18px;
                            li {
                                a {
                                    font-size: 14px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.header-sidebar-footer {
    border-top: 1px solid #a3a3a3;
    border-bottom: 1px solid #a3a3a3;
    padding: 38px 0 35px;
    h3 {
        color: #1b1b1b;
        margin: 0;
        font-size: 14px;
    }
    .footer-sidebar-widget {
        margin: 15px 0 47px;
        p {
            font-size: 11px; 
            color: #1b1b1b;
            margin: 0 0 3px;
            &:last-child {
                margin: 0 0 0px;
            }
        }
    }
}

.search-3-area {
    position: relative;
}
.search-3-area {
    .search-toggle {
        z-index: 99;
        i {
            display: block;
            &.s-close {
                left: 0;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
            }
        }
        .s-close {
            opacity: 0;
        }
        &.open {
            .s-close {
                opacity: 1;
            }
            .s-open {
                opacity: 0;
            }
        }
    }
}

.search-wrap-3 {
    background-color: transparent;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    transition: all 0.3s ease 0s;
    visibility: hidden;
    width: 340px;
    z-index: -99;
    padding-bottom: 1px;
    margin-right: 10px;
    form {
        position: relative;
        overflow: hidden;
        input {
            background-color: #fff;
            border: 1px solid #ddd;
            color: #393939;
            line-height: 30px;
            padding: 2px 20px;
            width: 100%;
            &::-moz-input-placeholder {
                color: #393939;
                opacity: 1;
            }
            &::-webkit-input-placeholder {
                color: #393939;
                opacity: 1;
            }
        }
    }
    &.open {
        opacity: 1;
        right: 100%;
        visibility: visible;
        z-index: 99;
    }
}

.category-menu {
    width: 290px;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 99;
    background-color: #fff;
    padding: 33px 0 35px;
    box-shadow: 0 0 30px 0.4px rgba(0, 0, 0, 0.1);
    display: none;
    @media #{$md-layout} {
        left: 50%;
        transform: translateX(-50%);
    }
    @media #{$xs-layout} {
        left: 50%;
        transform: translateX(-50%);
    }
    nav {
        > ul {
            > li {
                margin-bottom: 17px;
                position: relative;
                &:last-child {
                    margin-bottom: 0px;
                }
                @media #{$xx-layout} {
                    margin-bottom: 8px;
                }
                @media #{$xl-layout} {
                    margin-bottom: 8px;
                }
                @media #{$lg-layout} {
                    margin-bottom: 6px;
                }
                > a {
                    padding: 0 40px;
                    display: block;
                    font-size: 11px;
                    letter-spacing: 1.82px;
                    text-transform: uppercase;
                    font-weight: 600;
                    span {
                        float: right;
                        font-size: 14px;
                        position: relative;
                        top: 1px;
                    }
                }
                .category-menu-dropdown {
                    position: absolute;
                    left: 100%;
                    top: -22px;
                    width: 570px;
                    background-color: #fff;
                    padding: 30px 52px 40px 30px;
                    transform: rotateX(90deg);
                    transform-origin: center top 0;
                    transition: all 0.5s ease 0s;
                    visibility: hidden;
                    z-index: 99;
                    box-shadow: 0 0 30px 0.4px rgba(0, 0, 0, 0.1);
                    .single-category-menu {
                        display: inline-block;
                        width: 50%;
                        float: left;
                        &.ct-menu-mrg-bottom {
                            margin-bottom: 74px;
                        }
                        &.ct-menu-mrg-left {
                            padding-left: 38px;
                        }
                        &.category-menu-border {
                            position: relative;
                            &:before {
                                position: absolute;
                                content: "";
                                bottom: -36px;
                                left: 0;
                                background-color: #d6d7da;
                                height: 1px;
                                width: 180px;
                            }
                        }
                        h4 {
                            font-weight: 600;
                            font-size: 11px;
                            margin: 0 0 18px;
                            letter-spacing: 1.82px;
                            text-transform: uppercase;
                        }
                        ul {
                            li {
                                padding: 0 0 9px;
                                &:last-child {
                                    padding: 0 0 0px;
                                }
                                a {
                                    font-size: 12px;
                                    &:hover {
                                        color: $theme-color-yellow;
                                    }
                                }
                            }
                        }
                        > a {
                            img {
                                width: 100%;
                            }
                        }
                    }
                    @media #{$xx-layout} {
                        &.ct-menu-res-height-1 {
                            height: 280px;
                            overflow-y: auto;
                        }
                    }
                    @media #{$xl-layout} {
                        &.ct-menu-res-height-1 {
                            height: 280px;
                            overflow-y: auto;
                        }
                    }
                    @media #{$lg-layout} {
                        &.ct-menu-res-height-1 {
                            height: 280px;
                            overflow-y: auto;
                        }
                    }
                }
                > .cr-menu-desktop-none {
                    display: none;
                    padding: 15px 0 0 60px;
                    > li {
                        padding: 0 0 6px;
                        &:last-child {
                            padding: 0 0 0px;
                        }
                        > a {
                            display: block;
                            padding: 0 40px 0 0;
                            font-size: 11px;
                            letter-spacing: 1.82px;
                            text-transform: uppercase;
                            font-weight: 600;
                            span {
                                top: 4px;
                            }
                            i {
                                float: right;
                                font-size: 12px;
                                top: 7px;
                                position: relative;
                            }
                        }
                        ul {
                            padding: 10px 0 0;
                            li {
                                padding-bottom: 3px;
                                &:last-child {
                                    padding-bottom: 0px;
                                }
                                a {
                                    padding: 0 0px 0 20px;
                                    font-size: 12px;
                                }
                            }
                        }
                    }
                }
                &:hover > a , &:hover > a span {
                    color: $theme-color-yellow;
                }
                &:hover .category-menu-dropdown {
                    transform: rotateX(0deg);
                    visibility: visible;
                }
            }
        }
    }
}

.category-menu-2 {
    padding: 24px 0 27px;
    nav {
        ul {
            display: flex;
            justify-content: center;
            li {
                margin: 0 50px;
                @media #{$xx-layout} {
                    margin: 0 40px;
                }
                @media #{$xl-layout} {
                    margin: 0 35px;
                }
                @media #{$lg-layout} {
                    margin: 0 15px;
                }
                a {
                    font-size: 16px;
                    font-weight: 600;
                    color: #1b1b1b;
                    letter-spacing: .13px;
                    display: inline-block;
                    position: relative;
                    @media #{$lg-layout} {
                        font-size: 14px;
                    }
                    &::after {
                        position: absolute;
                        left: 0;
                        right: 0;
                        margin: auto;
                        top: 37%;
                        bottom: -32px;
                        width: 20%;
                        height: 4px;
                        content: "";
                        z-index: 1;
                        opacity: 0;
                        transition: all 0.3s;
                        background-color: $theme-color-yellow-2;
                    }
                    &.active::after {
                        width: calc(100%);
                        opacity: 1;
                    }
                }
                &:hover > a::after {
                    width: calc(100%);
                    opacity: 1;
                }
            }
        }
    }
}

.kid-main-menu-wrap {
	margin-bottom: -112px;
	z-index: 999;
	position: relative;
    @media #{$xx-layout} {
        margin-bottom: -80px;
    }
    @media #{$xl-layout} {
        margin-bottom: -50px;
    }
    @media #{$lg-layout} {
        margin-bottom: -50px;
    }
    .kid-menu-bg-img {
        height: 148px;
        display: flex;
        align-items: center;
        justify-content: center;
        @media #{$xx-layout} {
            height: 100px;
        }
        @media #{$xl-layout} {
            height: 85px;
        }
        @media #{$lg-layout} {
            height: 78px;
        }
        .kid-main-menu {
            padding-top: 30px;
            @media #{$xx-layout} {
                padding-top: 15px;
            }
            @media #{$xl-layout} {
                padding-top: 15px;
            }
            @media #{$lg-layout} {
                padding-top: 12px;
            }
        }
    }
}
.header-ptb-3 {
	padding: 57px 0 30px;
}








