/*-------- 22. Shop style ---------*/

.shop-top-bar {
    display: flex;
    justify-content: space-between;
    width: 100%;
    @media #{$xs-layout} {
        display: block;
    }
    .shop-top-bar-left {
        display: flex;
        align-items: center;
        @media #{$xs-layout} {
            display: block;
        }
        @media #{$sm-layout} {
            display: flex;
        }
        .shop-page-list {
            line-height: 1;
            @media #{$xs-layout} {
                margin-bottom: 20px;
            }
            @media #{$sm-layout} {
                margin-top: 0px;
            }
            ul {
                li {
                    display: inline-block;
                    color: #1d1d1d;
                    margin-right: 3px;
                    font-size: 14px;
                    &:last-child {
                        margin-right: 0px;
                    }
                    &.active {
                        a {
                            color: #262626;
                        }
                    }
                    a {
                        color: #1d1d1d;
                        font-size: 14px;
                        &:hover {
                            color: $theme-color-yellow;
                        }
                    }
                }
            }
        }
    }
    .shop-top-bar-right {
        display: flex;
        justify-content: space-between;
        align-items: center;
        @media #{$xs-layout} {
            display: block;
        }
        @media #{$sm-layout} {
            display: flex;
        }
        .shop-filter {
            line-height: 1;
            position: relative;
            display: inline-block;
            a {
                color: #1B1B1B;
                display: inline-block;
                border: 1px solid #d8d8d8;
                padding: 14px 20px 14px 20px;
                i {
                    color: #1B1B1B;
                    margin-left: 20px;
                }
                &.active {
                    color: $theme-color-yellow;
                    i {
                        color: $theme-color-yellow;
                    }
                }
            }
            &:hover {
                a {
                    color: $theme-color-yellow;
                }
            }
            @media #{$xs-layout} {
                &.ml-25 {
                    margin-left: 0;
                }
            }
        }
        .shop-short-by {
            position: relative;
            display: inline-block;
            line-height: 1;
            > span {
                color: #1B1B1B;
                position: relative;
                cursor: pointer;
                display: inline-block;
                border: 1px solid #d8d8d8;
                padding: 14px 20px 14px 20px;
                transition: all .3s ease 0s;
                i {
                    color: #1B1B1B;
                    margin-left: 20px;
                }
            }
            ul {
                right: 0;
                top: 100%;
                position: absolute;
                background-color: #fff;
                padding: 7px;
                z-index: 11;
                visibility: hidden;
                opacity: 0;
                box-shadow: 0 0 32px 0 rgba(0, 0, 0, 0.2);
                transition: all 250ms ease-out;
                margin-top: 30px;
                @media #{$xs-layout} {
                    right: auto;
                    left: 0;
                }
                li {
                    display: block;
                    a {
                        padding: 5px 10px;
                        white-space: pre;
                        display: block;
                        border-radius: 4px;
                        font-size: 0.92em;
                        color: #777777;
                        line-height: 24px;
                        &:hover {
                            color: #fff;
                            background-color: $theme-color-yellow;
                        }
                    }
                    &.active {
                        a {
                            color: #fff;
                            background-color: $theme-color-yellow;
                        }
                    }
                }
            }
            &:hover ul {
                opacity: 1;
                visibility: visible;
                margin-top: 0px;
            }
            &:hover {
                > span {
                    color: $theme-color-yellow;
                    i {
                        color: $theme-color-yellow;
                    }
                }
            }
            @media #{$xs-layout} {
                &.ml-40 {
                    margin-left: 0;
                    margin-top: 12px;
                    margin-bottom: 16px;
                }
            }
            @media #{$sm-layout} {
                &.ml-40 {
                    margin-left: 0;
                    margin-top: 0px;
                    margin-bottom: 0px;
                }
            }
        }
        .shop-tab {
            a {
                color: #BEBEBE;
                font-size: 22px;
                margin-right: 20px;
                &:last-child {
                    margin-right: 0;
                }
                &.active {
                    color: #191919;
                }
            }
        }
    }
}

.product-filter-wrapper {
	box-shadow: 0 0 32px 0 rgba(0, 0, 0, 0.05);
	margin-bottom: 30px;
	margin-top: 30px;
	font-size: 12px;
    padding: 48px 40px 18px;
    display: none;
    width: 100%;
    @media #{$md-layout} {
        padding: 40px 25px 34px;
    }
    @media #{$xs-layout} {
        padding: 40px 25px 34px;
    }
    .product-filter {
        h5 {
            font-size: 16px;
            margin: 0 0 30px;
            text-transform: capitalize;
            font-weight: 600;
            color: #1B1B1B;
            @media #{$xs-layout} {
                margin: 0 0 10px;
            }
        }
        .price-filter , .product-size , .product-color , .product-brands {
            ul {
                li {
                    margin-bottom: 7px;
                    display: block;
                    &:last-child {
                        margin-bottom: 0;
                    }
                    a {
                        color: #1B1B1B;
                        font-size: 14px;
                        &:hover {
                            color: $theme-color-yellow;
                        }
                    }
                }
            }
        }
        .product-color , .product-size  , .product-brands {
            ul {
                width: 100%;
                li {
                    width: 50%;
                    display: inline-block;
                    float: left;
                }
            }
        }
    }
}


.shop-list-content {
    h3 {
        font-size: 24px;
        margin: 0 0 20px;
        line-height: 32px;
        @media #{$lg-layout} {
            margin: 0 0 18px;
            font-size: 23px;
        }
        @media #{$md-layout} {
            margin: 0 0 12px;
            font-size: 22px;
        }
        @media #{$xs-layout} {
            font-size: 19px;
            margin: 0 0 10px;
            line-height: 28px;
        }
    }
    .pro-list-price {
        span {
            font-size: 18px;
            &.old {
                color: #777;
                margin-right: 5px;
            }
        }
    }
    p {
        line-height: 27px;
        color: #737373;
        margin: 12px 0 45px;
        @media #{$lg-layout} {
            margin: 10px 0 20px;
        }
        @media #{$md-layout} {
            margin: 10px 0 20px;
        }
        @media #{$xs-layout} {
            margin: 10px 0 20px;
        }
    }
    .product-list-action {
        display: flex;
        align-items: center;
        > a {
            display: inline-block;
            font-weight: bold;
            color: #2f2f2f;
            font-size: 25px;
            text-align: center;
            &:hover {
                color: $theme-color-yellow;
            }
        }
        .pro-list-actioncart {
            margin: 0 30px 0;
            a {
                display: inline-block;
                font-size: 16px;
                color: #fff;
                padding: 22px 50px;
                background-color: #262626;
                line-height: 1;
                @media #{$md-layout} {
                    padding: 22px 40px;
                }
                @media #{$xs-layout} {
                    padding: 18px 30px;
                }
                @media #{$sm-layout} {
                    padding: 15px 20px;
                }
                &:hover {
                    background-color: $theme-color-yellow;
                }
            }
        }
    }
    @media #{$xs-layout} {
        margin-top: 18px;
    }
    @media #{$sm-layout} {
        margin-top: 0px;
    }
}

.shop-list-wrap {
    &:hover {
        .shop-list-quickview {
            a {
                transform: scale(1);
                opacity: 1;
            }
        }
        .product-img::after {
            right: 24px;
            left: 24px;
            top: 24px;
            bottom: 24px;
            opacity: 1;
        }
    }
}

.pro-pagination-style {
    ul {
        li {
            display: inline-block;
            margin: 0 8px;
            @media #{$xs-layout} {
                margin: 0 4px;
            }
            a {
                border: 1px solid #CdCdCd;
                color: #1B1B1B;
                font-size: 12px;
                text-align: center;
                display: inline-block;
                width: 38px;
                height: 38px;
                line-height: 38px;
                border-radius: 100%;
                &.active , &:hover {
                    background-color: #232830;
                    color: #fff;
                    border: 1px solid #232830;
                }
                i {
                    line-height: 38px;
                }
            }
            &:last-child {
                position: relative;
                top: 2px;
            }
        }
    }
    @media #{$xs-layout} {
        &.mt-30 {
            margin-top: 0;
        }
    }
}

.shop-collections-img {
    overflow: hidden;
    a {
        display: block;
        img {
            width: 100%;
            transform: scale(1);
            transition: all .5s ease 0s;
        }
    }
}

.shop-collections-content {
    span {
        font-size: 16px;
        font-weight: 300;
        color: #1D1D1D;
    }
    h2 {
        font-size: 46px;
        font-family: $playfairdisplay;
        font-style: italic;
        color: #1D1D1D;
        margin: 5px 0 22px;
        @media #{$lg-layout} {
            font-size: 42px;
        }
        @media #{$md-layout} {
            font-size: 32px;
        }
        @media #{$xs-layout} {
            font-size: 28px;
            margin: 5px 0 12px;
        }
        a {
           color: #1D1D1D;
            &:hover {
                color: $theme-color-yellow;
            }
        }
    }
    p {
        font-size: 16px;
        line-height: 34px;
        color: #707070;
        margin: 0 0 31px;
        @media #{$md-layout} {
            font-size: 15px;
            line-height: 30px;
        }
        @media #{$xs-layout} {
            font-size: 15px;
            line-height: 30px;
            margin: 0 0 15px;
        }
    }
    .collections-btn {
        a {
            display: inline-block;
            background-color: #1D1D1D;
            color: #fff;
            text-transform: uppercase;
            font-weight: bold;
            font-size: 12px;
            padding: 20px 35px;
            line-height: 1;
            z-index: 2;
            position: relative;
        }
    }
    @media #{$lg-layout} {
        &.ml-70 {
            margin-left: 0;
        }
    }
    @media #{$md-layout} {
        &.ml-70 {
            margin-left: 0;
        }
    }
    @media #{$xs-layout} {
        margin-top: 10px;
        &.ml-70 {
            margin-left: 0;
        }
    }
}

.shop-collections-wrap {
    &:hover {
        .shop-collections-img {
            a {
                img {
                    transform: scale(1.1);
                }
            }
        }
    }
    @media #{$md-layout} {
        &.mb-90 {
            margin-bottom: 50px;
        }
    }
    @media #{$xs-layout} {
        &.mb-90 {
            margin-bottom: 50px;
        }
    }
}

.shop-instagram-wrap {
    a {
        display: block;
        img {
            width: 100%;
        }
    }
}
.toggle-item-active {
	display: none;
}
.instra-btn {
    a {
        text-transform: uppercase;
        font-weight: bold;
        color: #1D1D1D;
        font-size: 12px;
        line-height: 1;
        border: 1px solid #D2D2D2;
        padding: 23px 50px;
        display: inline-block;
        &:hover {
            background-color: $theme-color-yellow;
            border: 1px solid $theme-color-yellow;
            color: #fff;
        }
    }
    @media #{$xs-layout} {
        &.mt-50 {
            margin-top: 10px;
        }
    }
}




