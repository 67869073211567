/*-------- 30. Contact style ---------*/

.contact-form-area {
    margin-right: 50px;
    @media #{$lg-layout} {
        margin-right: 0px;
    }
    @media #{$md-layout} {
        margin-right: 0px;
    }
    @media #{$xs-layout} {
        margin-right: 0px;
    }
    h2 {
        font-weight: bold;
        font-size: 32px;
        margin: 0 0 42px;
        color: #262626;
        text-transform: uppercase;
        @media #{$md-layout} {
            font-size: 25px;
            margin: 0 0 20px;
        }
        @media #{$xs-layout} {
            font-size: 25px;
            margin: 0 0 20px;
        }
    }
    input , textarea  , select {
        height: 70px;
        background-color: transparent;
        border: 1px solid #D8D8D8;
        color: #5d5d5d;
        padding: 2px 23px;
        margin-bottom: 20px;
        @media #{$xs-layout} {
            height: 50px;
        }
        &::-webkit-input-placeholder {
            color: #5d5d5d;
            opacity: 1;
        }
    }
    textarea { 
        padding: 20px 20px;
        height: 180px;
        @media #{$md-layout} {
            height: 160px
        }
        @media #{$xs-layout} {
            height: 120px
        }
    }
    select { 
        -moz-appearance: none;
        -webkit-appearance: none;
        background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='32' height='24' viewBox='0 0 32 24'><polygon points='0,0 32,0 16,24' style='fill: rgb%28138, 138, 138%29'></polygon></svg>");
        background-size: 9px 6px;
        background-position: right -10px center;
        background-origin: content-box;
        background-repeat: no-repeat;
        max-width: 100%;
    }
    button {
        &.submit {
            background-color: #181818;
            color: #fff;
            padding: 13px 45px 13px;
            border: none;
            font-weight: bold;
            &:hover {
                background-color: $theme-color-yellow;
            }
        }
    }
}

.contact-info-area {
    @media #{$xs-layout} {
        margin: 30px 0 0px;
    }
    h2 {
        font-weight: bold;
        font-size: 32px;
        margin: 0 0 42px;
        color: #262626;
        text-transform: uppercase;
        @media #{$md-layout} {
            font-size: 25px;
            margin: 0 0 20px;
        }
        @media #{$xs-layout} {
            font-size: 25px;
            margin: 0 0 20px;
        }
    }
    .contact-info-top {
        .sin-contact-info-wrap {
            .contact-address {
                display: flex;
                align-items: center;
                margin: 0 0 13px;
                i {
                    color: #2F2F2F;
                    font-size: 20px;
                }
                span {
                    font-weight: bold;
                    color: #282828;
                    margin: 0 0 0 14px;
                    display: inline-block;
                }
            }
            p {
                font-size: 12px;
                line-height: 18px;
                margin: 0;
            }
        }
    }
    .contact-info-bottom {
        border-top: 1px solid rgba(119,119,119,0.2);
        margin-top: 34px;
        padding-top: 33px;
        ul {
            li {
                font-size: 12px;
                margin: 0 0 15px;
                color: #262626;
                &:last-child {
                    margin-bottom: 0;
                }
                i {
                    font-size: 20px;
                    position: relative;
                    top: 5px;
                    margin-right: 14px;
                }
            }
        }
        .contact-info-social {
            margin: 40px 0 0;
            a {
                font-size: 20px;
                margin-right: 17px;
            }
        }
    }
}


#contact-map {
    height: 300px;
}





